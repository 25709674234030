import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { TextI18n } from "../input/textI18n";
import { SubmitButton } from "../input/submitButton";
import { AdditionalSettings } from "../../models/requests/additionalSettings";
import {addNotification} from "../../utils/toast/toast";

export function AdditionalSettingsModule() {
    const { user, menu } = useUserContext();
    const {i18n, api} = useGlobalContext();

    const [text, setText] = useState<{ [lang: string]: string } | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [additionalSettings, setAdditionalSettings] = useState<AdditionalSettings>({
        orgId: user?.org?.id || "",
        data: {},
    });

    const [menus, setMenus] = useState<{ value: string; label: string }[]>([
        { value: "all", label: "all" },
    ]);

    const [selectedMenuId, setSelectedMenuId] = useState<string>("");

    useEffect(() => {
        if (user?.org?.menus?.menus) {
            setMenus([
                { value: "all", label: "all" },
                ...user.org.menus.menus.map((menu: any) => ({
                    value: menu.id,
                    label: menu.name,
                })),
            ]);
        }
    }, [user]);

    const handleSelectMenu = (option: { value: string; label: string } | null) => {
        if (!option) return;
        setSelectedMenuId(option.value);

        if(text) {
            setAdditionalSettings((prev) => ({
                ...prev,
                data: {
                    [option.value]: {
                        "modalMessage": {"text": text, "btnText": {
                                "KZ": "Келісемін",
                                "RU": "Согласен(-на)",
                                "EN": "Agree"
                            }}
                    },
                },
            }));
        }
    };


    const handleTextChange = (updatedText: { [lang: string]: string }) => {
        setText(updatedText);

        if (selectedMenuId) {
            setAdditionalSettings((prev) => ({
                ...prev,
                data: {
                    [selectedMenuId]: {
                        "modalMessage": {"text": updatedText, "btnText": {
                                "KZ": "Келісемін",
                                "RU": "Согласен(-на)",
                                "EN": "Agree"
                            }}
                    },
                },
            }));
        }
    };

    const handleSave = async () => {
        if (!selectedMenuId) {
            addNotification({message: i18n.select_menu_two(), type: "info"})
            return;
        }

        await api.addOrgCrutches({setLoading: setLoading}, additionalSettings)
    };

    return (
        <form className="form">
            <div className="control">
                <Select
                    options={menus}
                    placeholder={i18n.select_menu_two()}
                    value={menus.find((m) => m.value === selectedMenuId) || null}
                    onChange={handleSelectMenu}
                />
            </div>

            <TextI18n
                values={text}
                setValues={handleTextChange}
                lang={menu.i18n.defaultLang}
                isStaff={true}
                allLang={["KZ", "RU", "EN"]}
                placeholder={i18n.enter_title()}
                label="Что хотите отображать"
            />

            <div className="form__buttons mt-4">
                <div className="form__buttons-left">
                    <SubmitButton
                        onSubmit={handleSave}
                        label="Сохранить"
                        disabled={!selectedMenuId}
                        isLoading={false}
                    />
                </div>
            </div>
        </form>
    );
}

import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import React, {useEffect, useRef, useState} from "react";
import {InputDate} from "../input/inputDate";
import {Button} from "../../migration/shared/ui/Button";
import {useOnClickOutside} from "../../utils/useHook/useHook";
import {Loading} from "../../migration/shared/ui/Loading";


interface ModalReportProps {
    modalOpen: boolean
    setModalOpen: (e: boolean) => void
}

export function ModalReport(props: ModalReportProps) {
    const {currentMenuId} = useUserContext();
    const { api, i18n } = useGlobalContext();

    const modalRef = useRef(null)

    useOnClickOutside(modalRef, () => props.setModalOpen(false), "mouseup")

    const [month, setMonth] = useState<Date>(new Date());
    const [loading, setLoading] = useState<boolean>(false);
    const [monthlyReports, setMonthlyReports] = useState<string>("");

    const getMonthlyReport = async (date: Date) => {
        if(!date || !currentMenuId) return;
        setLoading(true);
        await api.getMonthlyReport({ setLoading: setLoading }, {month: date.toISOString().slice(0,7), menuId: currentMenuId})
            .then(res => {
                setMonthlyReports(res?.data?.reportText || "");
            })
            .catch(err => {
                console.error('Ошибка при получении истории:', err);
            }).finally(() =>{
                setLoading(false);
            });
    }

    useEffect(() => {
        if (!props.modalOpen) {
            setMonth(new Date());
            setLoading(false);
            setMonthlyReports("");
        }
    }, [props.modalOpen]);

    const copyText = () => {
        if (!monthlyReports) return;
        const plainText = monthlyReports.replace(/<br\s*\/?>/gi, "\n").trim();
        navigator.clipboard.writeText(plainText).then(() => {
            console.log("Текст скопирован!");
        }).catch(err => {
            console.error("Ошибка копирования: ", err);
        });
    };

    return (
        <>
            <div className={props.modalOpen ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
                 id="modal-report-preview" style={{display: props.modalOpen ? "block" : "none"}}
                 aria-hidden={props.modalOpen}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={"modal-content o"} ref={modalRef}>
                        <div className="modal-body px-3 py-4 p-lg-5" >
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"
                                    onClick={() => props.setModalOpen(false)}>
                            </button>

                            <div>
                                <h3>{i18n.download_monthly_report()}</h3>

                                <div className="control">
                                    <InputDate
                                        label={""}
                                        value={month}
                                        onChange={setMonth}
                                        type={"month-year"}
                                        isValidate={true}/>
                                </div>

                                {monthlyReports && !loading &&
                                    <div className={"mb-4 overflow-auto"} style={{ maxHeight: '40vh' }} dangerouslySetInnerHTML={{ __html: monthlyReports }} >
                                    </div>
                                }

                                {loading && <div className={"mb-4"}>
                                    <Loading/>
                                </div>}

                                <div className={"modal-footer"}>

                                    {monthlyReports &&
                                        <Button text={i18n.copy()}
                                                onClick={copyText}
                                        />
                                    }

                                    <Button text={i18n.get()}
                                            disabled={loading}
                                            loading={loading}
                                            isFilled={true}
                                            onClick={() => {
                                                getMonthlyReport(month);
                                            }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                props.modalOpen ? <div className="modal-backdrop show"></div> : null
            }
        </>
    )
}
import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {
    IAnalyticsCustomerEvents,
    IAnalyticsOrders,
    IItemsNameMap,
    IMenuStats,
    NltxDemog
} from "../../models/nltx/IAnalytics";
import {CURRENCY} from "../../utils/options/options";
import {CSVLink} from "react-csv";
import {BlockLoader} from "../../components/loader/loader";
import {RouteNames} from "../../migration/pages";
import {InputDate} from "../../components/input/inputDate";
import {SubmitButton} from "../../components/input/submitButton";
import {Select} from "antd";


export const priceWithSpaceAndCurrency = (x: number, currency_code: string) => {
    return `${x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${currency_code}`;
}

export function Analytics() {
    const {currentMenuId, menu} = useUserContext()
    const {api, userInfoLoading, i18n} = useGlobalContext();
    const [isLoadingCustomerEvents, setIsLoadingCustomerEvents] = useState<boolean>(false);
    const [isLoadingOrders, setIsLoadingOrders] = useState<boolean>(false);
    const [isLoadingItemNames, setIsLoadingItemNames] = useState<boolean>(false);
    const [isLoadingVDynacmics, setIsLoadingVDynacmics] = useState<boolean>(false);
    const [isLoadingODynacmics, setIsLoadingODynacmics] = useState<boolean>(false);
    const [isLoadingDemog, setIsLoadingDemog] = useState<boolean>(false);
    const [isLoadingVisitStats, setIsLoadingVisitStats] = useState<boolean>(false);

    useEffect(() => {
        console.log("is loading customer events", isLoadingCustomerEvents)
    }, [isLoadingCustomerEvents])

    useEffect(() => {
        document.title = i18n.analytics()
    }, [i18n])

    const [analyticsCustomerEvents, setAnalyticsCustomerEvents] = useState<IAnalyticsCustomerEvents>({} as IAnalyticsCustomerEvents);
    const [customerEventsDynamics, setCustomerEventsDynamics] = useState<IAnalyticsCustomerEvents>({} as IAnalyticsCustomerEvents);
    const [analyticsOrders, setAnalyticsOrders] = useState<IAnalyticsOrders>({} as IAnalyticsOrders);
    const [ordersDynamics, setOrdersDynamics] = useState<IAnalyticsOrders>({} as IAnalyticsOrders);
    const [menuItemsName, setMenuItemsName] = useState<IItemsNameMap>({} as IItemsNameMap);
    const [ordersLoaded, setOrdersLoaded] = useState<boolean>(false);
    const [demogAnalytics, setDemogAnalytics] = useState<NltxDemog>({} as NltxDemog);
    const [firstSelectedSegment, setFirstSelectedSegment] = useState<string>("male 18-24");
    const [secondSelectedSegment, setSecondSelectedSegment] = useState<string>("female 18-24");
    const [visitingStats, setVisitingStats] = useState<IMenuStats>({} as IMenuStats);
    const [visitStatsPeriod, setVisitStatsPeriod] = useState<'WEEK' | 'MONTH' | '3MONTH' | 'PERIOD'>('WEEK');

    const fetchAnalyticsCustomerEvents = async (controller: AbortController) => {
        if (!currentMenuId) return;

        await api.getNltxCustomerEvents({controller, setLoading: setIsLoadingCustomerEvents}, currentMenuId)
            .then(response => setAnalyticsCustomerEvents(response?.data || {} as IAnalyticsCustomerEvents))
            .catch(() => setAnalyticsCustomerEvents({} as IAnalyticsCustomerEvents)).finally(() => {
                setIsLoadingCustomerEvents(false);
            })
    }

    const fetchAnalyticsOrders = async (controller: AbortController) => {
        setIsLoadingOrders(true)
        if (!currentMenuId) return;

        await api.getNltxOrders({controller, setLoading: setIsLoadingOrders}, currentMenuId)
            .then(response => setAnalyticsOrders(response?.data || {} as IAnalyticsOrders))
            .catch(() => setAnalyticsOrders({} as IAnalyticsOrders))
            .finally(() => {
                setIsLoadingOrders(false);
                setOrdersLoaded(true);
            })
    }

    const fetchAnalyticsDemog = async (controller: AbortController) => {
        setIsLoadingDemog(true)
        if (!currentMenuId) return;

        await api.getNltxDemog({controller, setLoading: setIsLoadingDemog}, {menuId: currentMenuId})
            .then(response => setDemogAnalytics(response?.data || {} as NltxDemog))
            .catch(() => setDemogAnalytics({} as NltxDemog))
            .finally(() => {
                setIsLoadingDemog(false);
            })
    }

    const fetchMenuItemsName = async (controller: AbortController) => {
        if (!currentMenuId) return;

        await api.getMenuItemsName({controller, setLoading: setIsLoadingItemNames}, currentMenuId)
            .then(response => setMenuItemsName(response?.data?.menuItemsName || {} as IItemsNameMap))
            .catch(() => setMenuItemsName({} as IItemsNameMap))
            .finally(() => setIsLoadingItemNames(false))
    }

    const fetchCustomerEventsDuringPeriod = async (controller: AbortController, startDate: Date, endDate: Date) => {
        if (!currentMenuId) return;

        await api.getNltxCustomerEventsDuringPeriod({controller, setLoading: setIsLoadingVDynacmics}, {
            menuId: currentMenuId,
            from: startDate.toISOString(),
            to: endDate.toISOString()
        })
            .then(response => setCustomerEventsDynamics(
                {
                    ...analyticsCustomerEvents,
                    visitsByDaysCount: response?.data?.visitsByDaysCount || [],
                    visitsByDaysDates: response?.data?.visitsByDaysDates || []
                } || {} as IAnalyticsCustomerEvents
            ))
            .catch(() => setCustomerEventsDynamics({} as IAnalyticsCustomerEvents)).finally(() => {
                setIsLoadingCustomerEvents(false);
            })
            .finally(() => setIsLoadingVDynacmics(false))
    }

    const fetchOrdersDuringPeriod = async (controller: AbortController, startDate: Date, endDate: Date) => {
        if (!currentMenuId) return;

        await api.getNltxOrdersDuringPeriod({controller, setLoading: setIsLoadingODynacmics}, {
            menuId: currentMenuId,
            from: startDate.toISOString(),
            to: endDate.toISOString()
        })
            .then(response => setOrdersDynamics(
                {
                    ...analyticsOrders,
                    ordersByDaysCount: response?.data?.ordersByDaysCount || [],
                    ordersByDaysDates: response?.data?.ordersByDaysDates || []
                } || {} as IAnalyticsOrders
            ))
            .catch(() => setOrdersDynamics({} as IAnalyticsOrders)).finally(() => {
                setIsLoadingOrders(false);
                setOrdersLoaded(true);
            })
            .finally(() => setIsLoadingODynacmics(false))
    }

    const fetchVisitingStatistics= async (controller: AbortController, fromDate: string, toDate: string) => {
        if (!currentMenuId || !fromDate || !toDate) return;

        await api.getMenuVisitingStatistics({controller, setLoading: setIsLoadingVisitStats}, {fromDate: fromDate, toDate: toDate, menuId: currentMenuId})
            .then(response => {
                setVisitingStats(response || {} as IMenuStats)
            })
            .catch(() => setVisitingStats({} as IMenuStats))
            .finally(() => setIsLoadingVisitStats(false))
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchAnalyticsCustomerEvents(controller);
        fetchAnalyticsOrders(controller);
        fetchMenuItemsName(controller);
        fetchAnalyticsDemog(controller);
        return () => controller.abort();
    }, [currentMenuId])

    useEffect(() => {
        if(visitStatsPeriod !== "PERIOD") {
            const controller = new AbortController();
            const today = new Date();
            let calculatedFromDate = new Date();

            switch (visitStatsPeriod) {
                case "WEEK": {
                    calculatedFromDate.setDate(today.getDate() - 7)
                    break;
                }
                case "MONTH": {
                    calculatedFromDate.setMonth(today.getMonth() - 1)
                    break;
                }
                case "3MONTH": {
                    calculatedFromDate.setMonth(today.getMonth() - 3)
                    break;
                }
            }

            const from = calculatedFromDate.toISOString().split('T')[0];
            const to = today.toISOString().split('T')[0];

            fetchVisitingStatistics(controller, from, to);

            return () => controller.abort();
        }
    }, [currentMenuId, visitStatsPeriod]);

    // Pie Chart

    useLayoutEffect(() => {
        if (Object.keys(analyticsCustomerEvents).length === 0) return;

        let x = am4core.create("pieVisits", am4charts.PieChart);
        x.logo.dispose();

        x.data = [
            {
                "category": i18n.via_qr(),
                "value": analyticsCustomerEvents?.totalVisitsQr || 0
            },
            {
                "category": i18n.via_link(),
                "value": analyticsCustomerEvents?.totalVisitsDirect || 0
            }
        ]

        let pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";

        x.innerRadius = am4core.percent(40);
        x.legend = new am4charts.Legend();
        x.legend.marginTop = 20;

        return () => {
            x.dispose();
        };
    }, [analyticsCustomerEvents]);


    // top10items Bar Chart Horizontal
    const [selectTopAmount] = useState(10);

    useLayoutEffect(() => {
        if (isLoadingCustomerEvents || isLoadingItemNames) return;

        let x = am4core.create("top10items", am4charts.XYChart);
        x.logo.dispose();
        x.bottomAxesContainer.layout = "horizontal";

        let interfaceColors = new am4core.InterfaceColorSet();

        let sortedData: [key: string, value: number][] = [];
        for (let key in analyticsCustomerEvents?.itemVisitsMap) {
            sortedData.push([menuItemsName[key]?.name[menu.i18n.defaultLang], analyticsCustomerEvents?.itemVisitsMap[key]]);
        }

        sortedData?.sort((a, b) => b[1] - a[1]);

        if (sortedData.length < selectTopAmount) {
            x.data = sortedData.map((item) => {
                return {
                    category: item[0],
                    value: item[1]
                }
            }).reverse() || [];
        } else {
            x.data = sortedData.slice(0, selectTopAmount).map((item) => {
                return {
                    category: item[0],
                    value: item[1]
                }
            }).reverse();
        }

        let categoryAxis = x.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.width = 150;
        categoryAxis.renderer.labels.template.truncate = true;
        categoryAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
        categoryAxis.renderer.grid.template.strokeOpacity = 1;
        categoryAxis.renderer.grid.template.location = 1;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.fontSize = 10;
        categoryAxis.renderer.fontWeight = 'bold';
        categoryAxis.renderer.opacity = 0.7;

        let valueAxis: any = x.xAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.marginRight = 30;
        valueAxis.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
        valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
        valueAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
        valueAxis.renderer.grid.template.strokeOpacity = 1;
        valueAxis.title.marginTop = 20;
        valueAxis.title.alignText = 'center';
        // valueAxis.title.text = i18n.visits();

        let series = x.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "category";
        series.dataFields.valueX = "value";
        series.xAxis = valueAxis;
        series.name = "Series";
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.fillOpacity = 0;
        bullet.strokeOpacity = 0;
        bullet.tooltipText = "{valueX.value}";

        x.cursor = new am4charts.XYCursor();
        x.cursor.behavior = "zoomY";
        x.scrollbarY = new am4core.Scrollbar();

        return () => {
            x.dispose();
        };
    }, [analyticsCustomerEvents, selectTopAmount, isLoadingCustomerEvents, isLoadingItemNames]);


    // vDynamic Bar Chart Vertical
    const [vDynamicPeriod, setVDynamicPeriod] = useState<'WEEK' | 'MONTH' | '3MONTH' | 'PERIOD'>('WEEK');

    const [vPeriodRange, setVPeriodRange] = useState<{ startDate: Date, endDate: Date }>({
        startDate: new Date(),
        endDate: new Date()
    });

    const [visitPeriodRange, setVisitPeriodRange] = useState<{ startDate: Date, endDate: Date }>({
        startDate: new Date(),
        endDate: new Date()
    });

    const setVPeriodRangeStartDate = (date: Date) => setVPeriodRange({...vPeriodRange, startDate: date});
    const setVPeriodRangeEndDate = (date: Date) => setVPeriodRange({...vPeriodRange, endDate: date});

    const setVisitPeriodRangeStartDate = (date: Date) => setVisitPeriodRange({...visitPeriodRange, startDate: date});
    const setVisitPeriodRangeEndDate = (date: Date) => setVisitPeriodRange({...visitPeriodRange, endDate: date});

    const onVPeriodRangeSubmit = async () => {
        const controller = new AbortController();
        await fetchCustomerEventsDuringPeriod(controller, vPeriodRange.startDate, vPeriodRange.endDate);
        return () => controller.abort();
    }

    const onVisitPeriodRangeSubmit = async () => {
        const controller = new AbortController();
        const from = visitPeriodRange.startDate.toISOString().split('T')[0];
        const to = visitPeriodRange.endDate.toISOString().split('T')[0];
        await fetchVisitingStatistics(controller, from, to);
        return () => controller.abort();
    }

    useLayoutEffect(() => {
        if (isLoadingCustomerEvents) return;
        if (!analyticsCustomerEvents?.visitsByDaysCount || !analyticsCustomerEvents?.visitsByDaysDates) return;
        if (vDynamicPeriod === 'PERIOD' && !customerEventsDynamics?.visitsByDaysCount?.length) return;

        let x = am4core.create("vDynamics", am4charts.XYChart);
        x.logo.dispose();

        switch (vDynamicPeriod) {
            case 'WEEK':
                x.data = analyticsCustomerEvents.visitsByDaysDates.slice(83, 90).map((item: any, index: number) => {
                    return {
                        date: analyticsCustomerEvents.visitsByDaysDates.slice(83, 90)[index],
                        value: analyticsCustomerEvents.visitsByDaysCount.slice(83, 90)[index]
                    }
                })
                break;
            case 'MONTH':
                x.data = analyticsCustomerEvents.visitsByDaysDates.slice(60, 90).map((item: any, index: number) => {
                    return {
                        date: analyticsCustomerEvents.visitsByDaysDates.slice(60, 90)[index],
                        value: analyticsCustomerEvents.visitsByDaysCount.slice(60, 90)[index]
                    }
                })
                break;
            case '3MONTH':
                x.data = analyticsCustomerEvents.visitsByDaysDates.slice(0, 90).map((item: any, index: number) => {
                    return {
                        date: analyticsCustomerEvents.visitsByDaysDates[index],
                        value: analyticsCustomerEvents.visitsByDaysCount[index]
                    }
                })
                break;
            case 'PERIOD':
                x.data = customerEventsDynamics.visitsByDaysDates.map((item: any, index: number) => {
                    return {
                        date: customerEventsDynamics.visitsByDaysDates[index],
                        value: customerEventsDynamics.visitsByDaysCount[index]
                    }
                })
                break;
        }

        x.language.locale["_date_millisecond"] = "mm:ss SSS";
        x.language.locale["_date_second"] = "HH:mm:ss";
        x.language.locale["_date_minute"] = "HH:mm";
        x.language.locale["_date_hour"] = "HH:mm";
        x.language.locale["_date_week"] = "ww";
        x.language.locale["_date_month"] = "MMM";
        x.language.locale["_date_year"] = "yyyy";

        // Create axes
        const dateAxis = x.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.renderer.fontSize = 11;
        dateAxis.renderer.labels.template.rotation = -45;
        dateAxis.renderer.labels.template.horizontalCenter = "right";

        x.language.locale["_date_day"] = "dd/MM/yyyy";
        x.dateFormatter.dateFormat = "dd/MM/yyyy";

        const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minGridDistance = 30;

        // Create series
        function createSeries(field: any, name: any) {
            const series = x.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.name = name;
            series.tooltipText = "{dateX}: [b]{valueY}[/]";
            series.strokeWidth = 2;

            series.smoothing = "monotoneX";

            const bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = am4core.color("#fff");
            bullet.circle.strokeWidth = 2;

            return series;
        }

        createSeries("value", `${vDynamicPeriod}`);

        x.cursor = new am4charts.XYCursor();
        x.cursor.behavior = "zoomX";

        return () => {
            x.dispose();
        }
    }, [analyticsCustomerEvents, vDynamicPeriod, isLoadingCustomerEvents, isLoadingItemNames, customerEventsDynamics])

    // vHeat Chart

    useLayoutEffect(() => {
        if (isLoadingCustomerEvents) return;

        let x = am4core.create("vHeat", am4charts.XYChart);
        x.logo.dispose();
        x.maskBullets = false;

        let xAxis = x.xAxes.push(new am4charts.CategoryAxis());
        let yAxis = x.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "weekday";
        yAxis.dataFields.category = "hour";

        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 40;

        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 30;

        let series = x.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "weekday";
        series.dataFields.categoryY = "hour";
        series.dataFields.value = "value";
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 3000;
        series.columns.template.width = am4core.percent(100);
        series.columns.template.height = am4core.percent(100);

        series.heatRules.push({
            target: series.columns.template,
            property: "fill",
            min: am4core.color("#ffffff"),
            max: am4core.color("#692155")
        });

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.stroke = am4core.color("#ffffff");
        columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";

        // heat legend
        let heatLegend = x.bottomAxesContainer.createChild(am4charts.HeatLegend);
        heatLegend.width = am4core.percent(100);
        heatLegend.series = series;
        heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
        heatLegend.valueAxis.renderer.minGridDistance = 30;

        // heat legend behavior
        series.columns.template.events.on("over", (event) => {
            handleHover(event.target);
        })

        series.columns.template.events.on("hit", (event) => {
            handleHover(event.target);
        })

        function handleHover(column: any) {
            if (!isNaN(column.dataItem.value)) {
                heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            } else {
                heatLegend.valueAxis.hideTooltip();
            }
        }

        series.columns.template.events.on("out", () => {
            heatLegend.valueAxis.hideTooltip();
        })

        if (!analyticsCustomerEvents) return;

        const data = analyticsCustomerEvents?.dayHourVisits?.map((week, indexWeek) => {
            const weekdaysFromi18n = i18n.days_of_week_short().split(',');
            const weekdays = [weekdaysFromi18n[1], ...weekdaysFromi18n.slice(2, 7), weekdaysFromi18n[0]];
            const hours = Array.from(Array(24).keys()).map((hour) => {
                return `${hour < 10 ? `0${hour}` : `${hour}`}:00`;
            });

            return week.map((day, indexDay) => {
                return {
                    weekday: weekdays[indexWeek],
                    hour: hours[indexDay],
                    value: day
                }
            })
        });

        x.data = data?.flat();

        return () => {
            x.dispose();
        }
    }, [analyticsCustomerEvents, isLoadingCustomerEvents]);


    // oDynamic Bar Chart Vertical
    const [oDynamicPeriod, setODynamicPeriod] = useState<'WEEK' | 'MONTH' | '3MONTH' | 'PERIOD'>('WEEK');

    const [oPeriodRange, setOPeriodRange] = useState<{ startDate: Date, endDate: Date }>({
        startDate: new Date(),
        endDate: new Date()
    });

    const setOPeriodRangeStartDate = (date: Date) => setOPeriodRange({...oPeriodRange, startDate: date});
    const setOPeriodRangeEndDate = (date: Date) => setOPeriodRange({...oPeriodRange, endDate: date});

    const onOPeriodRangeSubmit = async () => {
        const controller = new AbortController();
        await fetchOrdersDuringPeriod(controller, oPeriodRange.startDate, oPeriodRange.endDate);
        return () => controller.abort();
    }

    useLayoutEffect(() => {
        if (isLoadingOrders) return;
        if (!analyticsOrders?.ordersByDaysCount || !analyticsOrders?.ordersByDaysDates) return;
        if (oDynamicPeriod === 'PERIOD' && !ordersDynamics?.ordersByDaysCount?.length) return;

        let x = am4core.create("oDynamics", am4charts.XYChart);
        x.logo.dispose();

        switch (oDynamicPeriod) {
            case 'WEEK':
                x.data = analyticsOrders.ordersByDaysDates.slice(83, 90).map((item: any, index: number) => {
                    return {
                        date: analyticsOrders.ordersByDaysDates.slice(83, 90)[index],
                        value: analyticsOrders.ordersByDaysCount.slice(83, 90)[index]
                    }
                })
                break;
            case 'MONTH':
                x.data = analyticsOrders.ordersByDaysDates.slice(60, 90).map((item: any, index: number) => {
                    return {
                        date: analyticsOrders.ordersByDaysDates.slice(60, 90)[index],
                        value: analyticsOrders.ordersByDaysCount.slice(60, 90)[index]
                    }
                })
                break;
            case '3MONTH':
                x.data = analyticsOrders.ordersByDaysDates.slice(0, 90).map((item: any, index: number) => {
                    return {
                        date: analyticsOrders.ordersByDaysDates[index],
                        value: analyticsOrders.ordersByDaysCount[index]
                    }
                })
                break;
            case 'PERIOD':
                x.data = ordersDynamics.ordersByDaysDates.map((item: any, index: number) => {
                    return {
                        date: ordersDynamics.ordersByDaysDates[index],
                        value: ordersDynamics.ordersByDaysCount[index]
                    }
                })
                break;
        }

        x.language.locale["_date_millisecond"] = "mm:ss SSS";
        x.language.locale["_date_second"] = "HH:mm:ss";
        x.language.locale["_date_minute"] = "HH:mm";
        x.language.locale["_date_hour"] = "HH:mm";
        x.language.locale["_date_week"] = "ww";
        x.language.locale["_date_month"] = "MMM";
        x.language.locale["_date_year"] = "yyyy";

        // Create axes
        const dateAxis = x.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.renderer.fontSize = 11;
        dateAxis.renderer.labels.template.rotation = -45;
        dateAxis.renderer.labels.template.horizontalCenter = "right";

        x.language.locale["_date_day"] = "dd/MM/yyyy";
        x.dateFormatter.dateFormat = "dd/MM/yyyy";

        const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minGridDistance = 30;

        // Create series
        function createSeries(field: any, name: any) {
            const series = x.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.name = name;
            series.tooltipText = "{dateX}: [b]{valueY}[/]";
            series.strokeWidth = 2;

            series.smoothing = "monotoneX";

            const bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = am4core.color("#fff");
            bullet.circle.strokeWidth = 2;

            return series;
        }

        createSeries("value", `${oDynamicPeriod}`);

        x.cursor = new am4charts.XYCursor();
        x.cursor.behavior = "zoomX";

        return () => {
            x.dispose();
        }
    }, [analyticsOrders, oDynamicPeriod, isLoadingOrders, ordersDynamics]);


    // oHeat Chart
    useLayoutEffect(() => {
        let x = am4core.create("oHeat", am4charts.XYChart);
        x.logo.dispose();
        x.maskBullets = false;

        let xAxis = x.xAxes.push(new am4charts.CategoryAxis());
        let yAxis = x.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "weekday";
        yAxis.dataFields.category = "hour";

        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 40;

        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 30;

        let series = x.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "weekday";
        series.dataFields.categoryY = "hour";
        series.dataFields.value = "value";
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 3000;
        series.columns.template.width = am4core.percent(100);
        series.columns.template.height = am4core.percent(100);

        series.heatRules.push({
            target: series.columns.template,
            property: "fill",
            min: am4core.color("#ffffff"),
            max: am4core.color("#2b5ae3")
        });

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.stroke = am4core.color("#ffffff");
        columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";

        // heat legend
        let heatLegend = x.bottomAxesContainer.createChild(am4charts.HeatLegend);
        heatLegend.width = am4core.percent(100);
        heatLegend.series = series;
        heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
        heatLegend.valueAxis.renderer.minGridDistance = 30;

        // heat legend behavior
        series.columns.template.events.on("over", (event) => {
            handleHover(event.target);
        })

        series.columns.template.events.on("hit", (event) => {
            handleHover(event.target);
        })

        function handleHover(column: any) {
            if (!isNaN(column.dataItem.value)) {
                heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            } else {
                heatLegend.valueAxis.hideTooltip();
            }
        }

        series.columns.template.events.on("out", () => {
            heatLegend.valueAxis.hideTooltip();
        })

        if (!analyticsOrders) return;

        const data = analyticsOrders?.dayHourOrders?.map((week, indexWeek) => {
            const weekdaysFromi18n = i18n.days_of_week_short().split(',');
            const weekdays = [weekdaysFromi18n[1], ...weekdaysFromi18n.slice(2, 7), weekdaysFromi18n[0]];
            const hours = Array.from(Array(24).keys()).map((hour) => {
                return `${hour < 10 ? `0${hour}` : `${hour}`}:00`;
            });

            return week.map((day, indexDay) => {
                return {
                    weekday: weekdays[indexWeek],
                    hour: hours[indexDay],
                    value: day
                }
            })
        });

        x.data = data?.flat();

        return () => {
            x.dispose();
        }
    }, [analyticsOrders, isLoadingOrders]);

    // Demog Chart
    useLayoutEffect(() => {
        var chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;
        chart.logo.dispose();

        const hours = ["06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "00:00", "01:00", "02:00", "03:00", "04:00", "05:00"];

        const segments = ["male 0-18", "male 18-24", "male 25-34", "male 35-44", "male 45-54", "male 55-64", "female 0-18", "female 18-24", "female 25-34", "female 35-44", "female 45-54", "female 55-64",]

        const data: {
            "hour": string,
            "female 0-18": number,
            "female 18-24": number,
            "female 25-34": number,
            "female 35-44": number,
            "female 45-54": number,
            "female 55-64": number,
            "male 0-18": number,
            "male 18-24": number,
            "male 25-34": number,
            "male 35-44": number,
            "male 45-54": number,
            "male 55-64": number,
        }[] = hours.map((hour) => {
            return {
                "hour": hour,
                "female 0-18": 0,
                "female 18-24": 0,
                "female 25-34": 0,
                "female 35-44": 0,
                "female 45-54": 0,
                "female 55-64": 0,
                "male 0-18": 0,
                "male 18-24": 0,
                "male 25-34": 0,
                "male 35-44": 0,
                "male 45-54": 0,
                "male 55-64": 0,
            }
        });

        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < demogAnalytics?.visitsByHour?.length || 0; j++) {
                data[i].hour = demogAnalytics?.visitsByHour[j].hours[i];
                data[i] = {
                    ...data[i],
                    [demogAnalytics?.visitsByHour[j].segment]: Number(demogAnalytics?.visitsByHour[j].visits[i])
                }
            }
        }

        // Add data
        chart.data = data.reverse();

        chart.colors.step = 2;
        chart.padding(30, 30, 10, 30);
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";

        // Create axes
        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "hour";
        categoryAxis.renderer.minGridDistance = 10;
        // categoryAxis.renderer.grid.template.opacity = 0;
        // categoryAxis.renderer.grid.template.location = 0;
        // categoryAxis.renderer.minGridDistance = 30;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = true;
        // valueAxis.renderer.minWidth = 50;
        // valueAxis.renderer.grid.template.opacity = 0;
        // valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
        // valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        // valueAxis.renderer.ticks.template.length = 10;
        // valueAxis.renderer.line.strokeOpacity = 0.5;

        // Create series
        function createSeries(field: any, name: any) {
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.name = name;
            series.dataFields.valueX = field;
            series.dataFields.categoryY = "hour";
            series.sequencedInterpolation = true;

            series.stacked = true;

            series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}";

            var labelBullet = series.bullets.push(new am4charts.LabelBullet());
            // labelBullet.label.text = "{valueX}";
            labelBullet.locationY = 0.5;
            labelBullet.label.hideOversized = true;
        }

        segments.forEach((segment) => {
            createSeries(
                segment,
                segment.split(" ")[0] === "male"
                    ? `${i18n.male()} ${segment.split(" ")[1]}`
                    : `${i18n.female()} ${segment.split(" ")[1]}`
            );
        });

    }, [demogAnalytics, isLoadingDemog]);

    // Demog Pie Charts
    useLayoutEffect(() => {
        if (Object.keys(analyticsCustomerEvents).length === 0) return;

        let x = am4core.create("pie-segment-1", am4charts.PieChart);
        x.logo.dispose();

        const data = demogAnalytics?.itemsInterest?.map(item => {
            return {
                "category": item.itemName,
                "value": item.interestBy?.find(interest => interest.segment === firstSelectedSegment)?.count || 0,
            }
        })

        x.data = data?.sort((a, b) => b.value - a.value).slice(0, 10);

        let pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";

        x.innerRadius = am4core.percent(40);
        x.legend = new am4charts.Legend();
        x.legend.marginTop = 20;

        return () => {
            x.dispose();
        };
    }, [demogAnalytics, isLoadingDemog, firstSelectedSegment]);

    useLayoutEffect(() => {
        if (Object.keys(analyticsCustomerEvents).length === 0) return;

        let x = am4core.create("pie-segment-2", am4charts.PieChart);
        x.logo.dispose();

        const data = demogAnalytics?.itemsInterest?.map(item => {
            return {
                "category": item.itemName,
                "value": item.interestBy?.find(interest => interest.segment === firstSelectedSegment)?.count || 0,
            }
        })

        x.data = data?.sort((a, b) => b.value - a.value).slice(0, 10);

        let pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";

        x.innerRadius = am4core.percent(40);
        x.legend = new am4charts.Legend();
        x.legend.marginTop = 20;

        return () => {
            x.dispose();
        };
    }, [demogAnalytics, isLoadingDemog, secondSelectedSegment]);

    // Clustered Customer Portrait
    useLayoutEffect(() => {
        var chart = am4core.create('clusteredCustomerPortrait', am4charts.XYChart)
        chart.logo.dispose();
        chart.colors.step = 2;
        // chart.bottomAxesContainer.layout = "horizontal";

        chart.legend = new am4charts.Legend()
        chart.legend.position = 'bottom'
        chart.legend.paddingBottom = 20
        chart.legend.labels.template.maxWidth = 95

        var xAxis = chart.xAxes.push(new am4charts.ValueAxis())
        xAxis.min = 0;

        var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = 'category'
        yAxis.renderer.cellStartLocation = 0.1
        yAxis.renderer.cellEndLocation = 0.9
        yAxis.renderer.grid.template.location = 0;

        function createSeries(value: any, name: any) {
            var series = chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.valueX = value
            series.dataFields.categoryY = 'category'
            series.name = name
            series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
            series.columns.template.height = am4core.percent(100);
            series.sequencedInterpolation = true;

            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueX}";
            valueLabel.label.horizontalCenter = "left";
            valueLabel.label.dx = 10;
            valueLabel.label.hideOversized = false;
            valueLabel.label.truncate = false;

            var categoryLabel = series.bullets.push(new am4charts.LabelBullet());
            categoryLabel.label.text = "{name}";
            categoryLabel.label.horizontalCenter = "right";
            categoryLabel.label.dx = -10;
            categoryLabel.label.fill = am4core.color("#fff");
            categoryLabel.label.hideOversized = false;
            categoryLabel.label.truncate = false;
        }

        const data = [
            {
                category: '0-18',
                male: 0,
                female: 0
            },
            {
                category: '18-24',
                male: 0,
                female: 0
            },
            {
                category: '25-34',
                male: 0,
                female: 0
            },
            {
                category: '35-44',
                male: 0,
                female: 0
            },
            {
                category: '45-54',
                male: 0,
                female: 0
            },
            {
                category: '55-64',
                male: 0,
                female: 0
            },
        ]
        demogAnalytics?.visitsAll?.forEach((item) => {
            if (item.segment.split(' ')[0] === 'male') {
                const index = data.findIndex((d) => d.category === item.segment.split('male ')[1]);
                if (item.segment.split('male ')[1] === '0-0') {
                    const found = data.find((d) => d.category === '55-64');
                    if (found) {
                        found.male += item.count;
                    }
                }
                data[index] = {
                    ...data[index],
                    male: +item.count
                }
            } else if (item.segment.split(' ')[0] === 'female') {
                const index = data.findIndex((d) => d.category === item.segment.split('female ')[1]);
                if (item.segment.split('female ')[1] === '0-0') {
                    const found = data.find((d) => d.category === '55-64');
                    if (found) {
                        found.female += item.count;
                    }
                }
                data[index] = {
                    ...data[index],
                    female: +item.count
                }
            }
        })

        chart.data = data.reverse();

        // chart.cursor = new am4charts.XYCursor();
        // chart.cursor.behavior = "zoomX";

        createSeries('male', i18n.male());
        createSeries('female', i18n.female());
    }, [demogAnalytics, isLoadingDemog]);

    useLayoutEffect(() => {
        if (!visitingStats || Object.keys(visitingStats).length === 0) return;

        let chart = am4core.create("horizontalBarVisits", am4charts.XYChart);
        chart.logo.dispose();

        const chartData = Object.entries(visitingStats.visitsData || {}).map(([key, value]) => ({
            category: key.replace("more_than_", i18n.more_than() + " "),
            value: Math.max(0, parseInt(value, 10)), // Убедимся, что значения не меньше 0
        }));

        chart.data = chartData;

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20; // Расстояние между категориями

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = i18n.quantity();
        valueAxis.min = 0; // Устанавливаем минимальное значение оси X равным 0
        valueAxis.strictMinMax = true; // Гарантируем строгий диапазон

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = "value";
        series.dataFields.categoryY = "category";
        series.name = i18n.visits();
        series.columns.template.tooltipText = "{category}: [bold]{value}[/]";
        series.columns.template.fillOpacity = 0.8;

        return () => {
            chart.dispose();
        };
    }, [visitingStats]);



    const [salesPeriod, setSalesPeriod] = useState<'TODAY' | 'TOTAL'>('TODAY');
    const todaySalesSumm = useMemo(() => {
        if (Object.keys(analyticsOrders).length === 0) return 0;
        return Object.keys(analyticsOrders?.itemSalesMapToday || {}).reduce((acc, key) => {
            return acc + analyticsOrders?.itemSalesMapToday[key].salesSumm;
        }, 0)
    }, [analyticsOrders, isLoadingOrders]);
    const totalSalesSumm = useMemo(() => {
        if (Object.keys(analyticsOrders).length === 0) return 0;
        return Object.keys(analyticsOrders?.itemSalesMapTotal || {}).reduce((acc, key) => {
            return acc + analyticsOrders?.itemSalesMapTotal[key].salesSumm;
        }, 0)
    }, [analyticsOrders, isLoadingOrders]);

    const sortObj = (obj: any, sortBy: 'salesSumm' | 'salesCount') => {
        let sortable = [];
        for (let i in obj) {
            sortable.push([i, obj[i]]);
        }

        sortable.sort(function (a: any, b: any) {
            return a[1][sortBy] - b[1][sortBy];
        });

        return sortable
    }

    const [salesSortBy, setSalesSortBy] = useState<'salesSumm' | 'salesCount'>('salesCount');
    const [salesSortReverse, setSalesSortReverse] = useState<boolean>(false);

    const salesToday = useMemo(() => {
        if (Object.keys(analyticsOrders).length === 0) return [];
        if (salesSortReverse) {
            return sortObj(analyticsOrders?.itemSalesMapToday || {}, salesSortBy);
        }
        return sortObj(analyticsOrders?.itemSalesMapToday || {}, salesSortBy).reverse();
    }, [analyticsOrders, isLoadingOrders, isLoadingItemNames, salesSortBy, salesSortReverse])

    const salesTotal = useMemo(() => {
        if (Object.keys(analyticsOrders).length === 0) return [];
        if (salesSortReverse) {
            return sortObj(analyticsOrders?.itemSalesMapTotal || {}, salesSortBy);
        }
        return sortObj(analyticsOrders?.itemSalesMapTotal || {}, salesSortBy).reverse();
    }, [analyticsOrders, isLoadingOrders, isLoadingItemNames, salesSortBy, salesSortReverse])

    const csvDataToday = useMemo(() => {
        const data: any = [];
        if (analyticsOrders?.itemSalesMapToday) {
            if (Object?.keys(analyticsOrders?.itemSalesMapToday)?.length > 0) {
                salesToday?.map((key) => {
                    const item = analyticsOrders?.itemSalesMapToday[key[0]];
                    data.push({
                        name: menuItemsName[key[0]]?.name[menu.i18n.defaultLang],
                        salesCount: item?.salesCount,
                        salesSumm: priceWithSpaceAndCurrency(item?.salesSumm / 100 || 0 || 0, CURRENCY[menu.i18n.currency]),
                    })
                })
            }
        }
        return data;
    }, [analyticsOrders, isLoadingOrders, isLoadingItemNames, salesToday]);

    const csvDataTotal = useMemo(() => {
        const data: any = [];
        if (analyticsOrders?.itemSalesMapTotal) {
            if (Object?.keys(analyticsOrders?.itemSalesMapTotal)?.length > 0) {
                salesTotal?.map((key) => {
                    const item = analyticsOrders?.itemSalesMapTotal[key[0]];
                    data.push({
                        name: menuItemsName[key[0]]?.name[menu.i18n.defaultLang],
                        salesCount: item?.salesCount,
                        salesSumm: priceWithSpaceAndCurrency(item?.salesSumm / 100 || 0, CURRENCY[menu.i18n.currency]),
                    })
                })
            }
        }
        return data;
    }, [analyticsOrders, isLoadingOrders, isLoadingItemNames, salesTotal]);

    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <ul className="breadcrumbs">

                    <li className="breadcrumbs__item">
                        <a href={RouteNames.ORDERS} className="breadcrumbs__link">
                            {i18n.analytics()}
                        </a>
                    </li>

                </ul>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">
                            {i18n.analytics()}
                        </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-xl-3">
                        <div className="default-card mb-4" style={{position: 'relative'}}>
                            {
                                (isLoadingCustomerEvents)
                                    ?
                                    <BlockLoader/>
                                    :
                                    <React.Fragment>
                                        <h3>{i18n.visits()}</h3>
                                        <div className="d-flex flex-wrap mb-3">
                                            <div className="w-50">
                                                <div
                                                    className="dashboard-number">{analyticsCustomerEvents?.todayVisits || 0}</div>
                                                <div className="dashboard-subtext">{i18n.today()}</div>
                                            </div>
                                            <div className="w-50">
                                                <div
                                                    className="dashboard-number">{analyticsCustomerEvents?.totalVisits || 0}</div>
                                                <div className="dashboard-subtext">{i18n.total()}</div>
                                            </div>
                                        </div>
                                        <div id="pieVisits" style={{minHeight: '200px'}}></div>
                                    </React.Fragment>
                            }

                            <div className="default-card mb-4"
                                 style={{position: 'relative', padding: '20px 0'}}>
                                {
                                    (isLoadingCustomerEvents || isLoadingItemNames)
                                        ?
                                        <BlockLoader/>
                                        :
                                        <React.Fragment>
                                            <h3>{i18n.top10_items()}</h3>
                                            <div id="top10items" style={{minHeight: '500px'}}>

                                            </div>
                                        </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-9">
                        <div className="row">
                            <div className="col-lg-12 col-xl-7">
                                <div className="default-card mb-4" style={{position: 'relative'}}>
                                    {
                                        (isLoadingCustomerEvents)
                                            ?
                                            <BlockLoader/>
                                            :
                                            <React.Fragment>
                                                <h3>{i18n.dynamics_of_visits()}</h3>
                                                <ul className="nav default-tabs mb-2 mb-lg-3">
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${vDynamicPeriod === 'WEEK' ? ' active' : ''}`}
                                                            id="vdays7"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setVDynamicPeriod('WEEK')}
                                                        >
                                                            {i18n.n7_days()}
                                                        </div>
                                                    </li>
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${vDynamicPeriod === 'MONTH' ? ' active' : ''}`}
                                                            id="vdays30"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setVDynamicPeriod('MONTH')}
                                                        >
                                                            {i18n.n30_days()}
                                                        </div>
                                                    </li>
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${vDynamicPeriod === '3MONTH' ? ' active' : ''}`}
                                                            id="vdays90"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setVDynamicPeriod('3MONTH')}
                                                        >
                                                            {i18n.n90_days()}
                                                        </div>
                                                    </li>
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${vDynamicPeriod === 'PERIOD' ? ' active' : ''}`}
                                                            id="vdays90"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setVDynamicPeriod('PERIOD')}
                                                        >
                                                            {i18n.period()}
                                                        </div>
                                                    </li>
                                                </ul>

                                                {vDynamicPeriod === 'PERIOD' && (
                                                    <form
                                                        className="form"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "start",
                                                            flexWrap: "wrap",
                                                            gap: "10px"
                                                        }}
                                                    >
                                                        <InputDate
                                                            label={i18n.from()}
                                                            value={vPeriodRange.startDate}
                                                            start={vPeriodRange.startDate}
                                                            end={vPeriodRange.endDate}
                                                            onChange={setVPeriodRangeStartDate}
                                                            isValidate={true}/>
                                                        <InputDate
                                                            label={i18n.to()}
                                                            value={vPeriodRange.endDate}
                                                            start={vPeriodRange.startDate}
                                                            end={vPeriodRange.endDate}
                                                            onChange={setVPeriodRangeEndDate}
                                                            isValidate={true}/>

                                                        <div className="form__buttons">
                                                            <div className="form__buttons-left">
                                                                <SubmitButton
                                                                    style={{height: "38px"}}
                                                                    onSubmit={onVPeriodRangeSubmit}
                                                                    label={"OK"}
                                                                    isLoading={isLoadingCustomerEvents}
                                                                    disabled={isLoadingCustomerEvents}
                                                                />
                                                            </div>
                                                        </div>

                                                    </form>
                                                )}

                                                {isLoadingVDynacmics
                                                    ?
                                                    <BlockLoader/>
                                                    :
                                                    <div id="vDynamics" style={{minHeight: '370px'}}></div>
                                                }
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-5">
                                <div className="default-card mb-4" style={{position: 'relative'}}>
                                    {
                                        (isLoadingCustomerEvents)
                                            ?
                                            <BlockLoader/>
                                            :
                                            <React.Fragment>
                                                <h3>{i18n.visits_by_daytime()}</h3>
                                                <div id="vHeat" style={{minHeight: '415px'}}>

                                                </div>
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="w-100" style={{padding: 0}}>
                            <div className="default-card mb-4"
                                 style={{position: 'relative'}}>
                                <React.Fragment>
                                    <h3>{i18n.repeat_visits()}</h3>
                                    <ul className="nav default-tabs mb-2 mb-lg-3">
                                        <li className="default-tabs__item">
                                            <div
                                                className={`default-tabs__link${visitStatsPeriod === 'WEEK' ? ' active' : ''}`}
                                                id="vdays7"
                                                style={{cursor: 'pointer', transition: '.1s linear'}}
                                                onClick={() => setVisitStatsPeriod('WEEK')}
                                            >
                                                {i18n.n7_days()}
                                            </div>
                                        </li>
                                        <li className="default-tabs__item">
                                            <div
                                                className={`default-tabs__link${visitStatsPeriod === 'MONTH' ? ' active' : ''}`}
                                                id="vdays30"
                                                style={{cursor: 'pointer', transition: '.1s linear'}}
                                                onClick={() => setVisitStatsPeriod('MONTH')}
                                            >
                                                {i18n.n30_days()}
                                            </div>
                                        </li>
                                        <li className="default-tabs__item">
                                            <div
                                                className={`default-tabs__link${visitStatsPeriod === '3MONTH' ? ' active' : ''}`}
                                                id="vdays90"
                                                style={{cursor: 'pointer', transition: '.1s linear'}}
                                                onClick={() => setVisitStatsPeriod('3MONTH')}
                                            >
                                                {i18n.n90_days()}
                                            </div>
                                        </li>
                                        <li className="default-tabs__item">
                                            <div
                                                className={`default-tabs__link${visitStatsPeriod === 'PERIOD' ? ' active' : ''}`}
                                                id="vdays90"
                                                style={{cursor: 'pointer', transition: '.1s linear'}}
                                                onClick={() => setVisitStatsPeriod('PERIOD')}
                                            >
                                                {i18n.period()}
                                            </div>
                                        </li>
                                    </ul>

                                    {visitStatsPeriod === 'PERIOD' && (
                                        <form
                                            className="form"
                                            style={{
                                                display: "flex",
                                                alignItems: "start",
                                                flexWrap: "wrap",
                                                gap: "10px"
                                            }}
                                        >
                                            <div className="col-md-3">
                                                <InputDate
                                                    label={i18n.from()}
                                                    value={visitPeriodRange.startDate}
                                                    start={visitPeriodRange.startDate}
                                                    end={visitPeriodRange.endDate}
                                                    onChange={setVisitPeriodRangeStartDate}
                                                    isValidate={true}/>
                                            </div>
                                            <div className="col-11 col-md-3">
                                                <InputDate
                                                    label={i18n.to()}
                                                    value={visitPeriodRange.endDate}
                                                    start={visitPeriodRange.startDate}
                                                    end={visitPeriodRange.endDate}
                                                    onChange={setVisitPeriodRangeEndDate}
                                                    isValidate={true}/>
                                            </div>
                                            <div className="form__buttons">
                                                <div className="form__buttons-left">
                                                    <SubmitButton
                                                        style={{height: "38px"}}
                                                        onSubmit={onVisitPeriodRangeSubmit}
                                                        label={"OK"}
                                                        isLoading={isLoadingVisitStats}
                                                        disabled={isLoadingVisitStats}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                    {
                                        (isLoadingVisitStats)
                                            ?
                                            <BlockLoader/>
                                            :
                                            <div id="horizontalBarVisits"
                                                 style={{width: "100%", height: "370px"}}></div>
                                    }
                                </React.Fragment>
                            </div>
                        </div>
                    </div>

                </div>

                {
                    ((!ordersLoaded && isLoadingOrders) || (ordersLoaded && Object?.keys(analyticsOrders)?.length > 0)) && (
                        <div className="row">
                            <div className="col-lg-12 col-xl-3">
                                <div className="default-card mb-4">
                                    {
                                        (isLoadingOrders)
                                            ?
                                            <BlockLoader/>
                                            :
                                            <React.Fragment>
                                                <h3>{i18n.client_orders()}</h3>
                                                <div className="d-flex flex-wrap mb-3">
                                                    <div className="w-50">
                                                        <div
                                                            className="dashboard-number">{analyticsOrders.totalToday || 0}</div>
                                                        <div className="dashboard-subtext">{i18n.today()}</div>
                                                    </div>
                                                    <div className="w-50">
                                                        <div
                                                            className="dashboard-number">{analyticsOrders.totalCount || 0}</div>
                                                        <div className="dashboard-subtext">{i18n.total()}</div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="d-flex flex-wrap mb-3">
                                                    <div className="w-50">
                                                        <div
                                                            className="dashboard-number">{priceWithSpaceAndCurrency(analyticsOrders.avgOrdersSumm / 100 || 0, CURRENCY[menu.i18n.currency])}</div>
                                                        <div className="dashboard-subtext">{i18n.avg_summ()}</div>
                                                    </div>
                                                    <div className="w-50">
                                                        <div
                                                            className="dashboard-number">{priceWithSpaceAndCurrency(analyticsOrders.totalOrdersSumm / 100 || 0, CURRENCY[menu.i18n.currency])}</div>
                                                        <div className="dashboard-subtext">{i18n.total_summ()}</div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6">
                                <div className="default-card mb-4" style={{position: 'relative'}}>
                                    {
                                        (isLoadingOrders)
                                            ?
                                            <BlockLoader/>
                                            :
                                            <React.Fragment>
                                                <h3>{i18n.dynamics_of_orders()}</h3>
                                                <ul className="nav default-tabs mb-2 mb-lg-3">
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${oDynamicPeriod === 'WEEK' ? ' active' : ''}`}
                                                            id="odays7"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setODynamicPeriod('WEEK')}
                                                        >
                                                            {i18n.n7_days()}
                                                        </div>
                                                    </li>
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${oDynamicPeriod === 'MONTH' ? ' active' : ''}`}
                                                            id="odays30"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setODynamicPeriod('MONTH')}
                                                        >
                                                            {i18n.n30_days()}
                                                        </div>
                                                    </li>
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${oDynamicPeriod === '3MONTH' ? ' active' : ''}`}
                                                            id="odays90"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setODynamicPeriod('3MONTH')}
                                                        >
                                                            {i18n.n90_days()}
                                                        </div>
                                                    </li>
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${oDynamicPeriod === 'PERIOD' ? ' active' : ''}`}
                                                            id="odays90"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setODynamicPeriod('PERIOD')}
                                                        >
                                                            {i18n.period()}
                                                        </div>
                                                    </li>
                                                </ul>

                                                {oDynamicPeriod === 'PERIOD' && (
                                                    <form
                                                        className="form"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "start",
                                                            flexWrap: "wrap",
                                                            gap: "10px"
                                                        }}
                                                    >
                                                        <InputDate
                                                            label={i18n.from()}
                                                            value={oPeriodRange.startDate}
                                                            start={oPeriodRange.startDate}
                                                            end={oPeriodRange.endDate}
                                                            selectsStart={true}
                                                            onChange={setOPeriodRangeStartDate}
                                                            isValidate={true}/>
                                                        <InputDate
                                                            label={i18n.to()}
                                                            value={oPeriodRange.endDate}
                                                            start={oPeriodRange.startDate}
                                                            end={oPeriodRange.endDate}
                                                            selectsEnd={true}
                                                            onChange={setOPeriodRangeEndDate}
                                                            isValidate={true}/>
                                                        <div className="form__buttons">
                                                            <div className="form__buttons-left">
                                                                <SubmitButton
                                                                    style={{height: "38px"}}
                                                                    onSubmit={onOPeriodRangeSubmit}
                                                                    label={"OK"}
                                                                    isLoading={isLoadingOrders}
                                                                    disabled={isLoadingOrders}
                                                                />
                                                            </div>
                                                        </div>

                                                    </form>
                                                    )}

                                                {isLoadingODynacmics
                                                    ?
                                                    <BlockLoader/>
                                                    :
                                                    <div id="oDynamics" style={{minHeight: '370px'}}>

                                                    </div>
                                                }
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-3">
                                <div className="default-card mb-4" style={{position: 'relative'}}>
                                    {
                                        (isLoadingOrders)
                                            ?
                                            <BlockLoader/>
                                            :
                                            <React.Fragment>
                                                <h3>{i18n.orders_by_daytime()}</h3>
                                                <div id="oHeat" style={{minHeight: '415px'}}>

                                                </div>
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    ((!ordersLoaded && isLoadingOrders) || (ordersLoaded && Object?.keys(analyticsOrders)?.length > 0)) && (
                        <div className="row">
                            <div className="col-lg-12 col-xl-6">
                                <div className="default-card mb-4">
                                    {
                                        (isLoadingOrders || isLoadingItemNames)
                                            ?
                                            <BlockLoader/>
                                            :
                                            <React.Fragment>
                                                <h3>{i18n.sales_by_items()}</h3>
                                                <ul className="nav default-tabs mb-2 mb-lg-3">
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${salesPeriod === 'TODAY' ? ' active' : ''}`}
                                                            id="isalesToday"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setSalesPeriod('TODAY')}

                                                        >
                                                            {i18n.today()}
                                                        </div>
                                                    </li>
                                                    <li className="default-tabs__item">
                                                        <div
                                                            className={`default-tabs__link${salesPeriod === 'TOTAL' ? ' active' : ''}`}
                                                            id="isalesTotal"
                                                            style={{cursor: 'pointer', transition: '.1s linear'}}
                                                            onClick={() => setSalesPeriod('TOTAL')}
                                                        >
                                                            {i18n.total()}
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="default-table" id="table_item_sales_today">
                                                    <div className="p-2 text-right">
                                                        <CSVLink
                                                            data={salesPeriod === 'TODAY' ? csvDataToday : csvDataTotal}
                                                            style={{color: '#0252CC'}}>Download</CSVLink>
                                                    </div>
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th>{i18n.row()}</th>
                                                            <th
                                                                style={{cursor: 'pointer', transition: '.1s linear'}}
                                                                onClick={() => {
                                                                    setSalesSortBy('salesCount')
                                                                    setSalesSortReverse(!salesSortReverse)
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        position: 'relative',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <div>{i18n.amount()}</div>
                                                                    {
                                                                        salesSortBy === 'salesCount' && (
                                                                            salesSortReverse
                                                                                ?
                                                                                (
                                                                                    <div style={{
                                                                                        position: 'absolute',
                                                                                        right: '0',
                                                                                        top: '0',
                                                                                        transition: '.1s linear',
                                                                                    }}>
                                                                                        <svg width="20" height="20"
                                                                                             viewBox="0 0 20 20"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fillRule="evenodd"
                                                                                                  clipRule="evenodd"
                                                                                                  d="M5.29303 7.29301C5.48056 7.10553 5.73487 7.00022 6.00003 7.00022C6.26519 7.00022 6.5195 7.10553 6.70703 7.29301L10 10.586L13.293 7.29301C13.3853 7.19749 13.4956 7.12131 13.6176 7.0689C13.7396 7.01649 13.8709 6.98891 14.0036 6.98775C14.1364 6.9866 14.2681 7.0119 14.391 7.06218C14.5139 7.11246 14.6255 7.18672 14.7194 7.28061C14.8133 7.3745 14.8876 7.48615 14.9379 7.60905C14.9881 7.73195 15.0134 7.86363 15.0123 7.99641C15.0111 8.12919 14.9835 8.26041 14.9311 8.38241C14.8787 8.50441 14.8025 8.61476 14.707 8.707L10.707 12.707C10.5195 12.8945 10.2652 12.9998 10 12.9998C9.73487 12.9998 9.48056 12.8945 9.29303 12.707L5.29303 8.707C5.10556 8.51948 5.00024 8.26517 5.00024 8C5.00024 7.73484 5.10556 7.48053 5.29303 7.29301Z"
                                                                                                  fill="black"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <div style={{
                                                                                        position: 'absolute',
                                                                                        right: '0',
                                                                                        top: '0',
                                                                                        transition: '.1s linear',
                                                                                        transform: 'rotate(180deg)'
                                                                                    }}>
                                                                                        <svg width="20" height="20"
                                                                                             viewBox="0 0 20 20"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fillRule="evenodd"
                                                                                                  clipRule="evenodd"
                                                                                                  d="M5.29303 7.29301C5.48056 7.10553 5.73487 7.00022 6.00003 7.00022C6.26519 7.00022 6.5195 7.10553 6.70703 7.29301L10 10.586L13.293 7.29301C13.3853 7.19749 13.4956 7.12131 13.6176 7.0689C13.7396 7.01649 13.8709 6.98891 14.0036 6.98775C14.1364 6.9866 14.2681 7.0119 14.391 7.06218C14.5139 7.11246 14.6255 7.18672 14.7194 7.28061C14.8133 7.3745 14.8876 7.48615 14.9379 7.60905C14.9881 7.73195 15.0134 7.86363 15.0123 7.99641C15.0111 8.12919 14.9835 8.26041 14.9311 8.38241C14.8787 8.50441 14.8025 8.61476 14.707 8.707L10.707 12.707C10.5195 12.8945 10.2652 12.9998 10 12.9998C9.73487 12.9998 9.48056 12.8945 9.29303 12.707L5.29303 8.707C5.10556 8.51948 5.00024 8.26517 5.00024 8C5.00024 7.73484 5.10556 7.48053 5.29303 7.29301Z"
                                                                                                  fill="black"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                )
                                                                        )
                                                                    }
                                                                </div>
                                                            </th>
                                                            <th
                                                                style={{cursor: 'pointer', transition: '.1s linear'}}
                                                                onClick={() => {
                                                                    setSalesSortBy('salesSumm')
                                                                    setSalesSortReverse(!salesSortReverse)
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        position: 'relative',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <div>{i18n.summ()}</div>
                                                                    {
                                                                        salesSortBy === 'salesSumm' && (
                                                                            salesSortReverse
                                                                                ?
                                                                                (
                                                                                    <div style={{
                                                                                        position: 'absolute',
                                                                                        right: '0',
                                                                                        top: '0',
                                                                                        transition: '.1s linear',
                                                                                    }}>
                                                                                        <svg width="20" height="20"
                                                                                             viewBox="0 0 20 20"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fillRule="evenodd"
                                                                                                  clipRule="evenodd"
                                                                                                  d="M5.29303 7.29301C5.48056 7.10553 5.73487 7.00022 6.00003 7.00022C6.26519 7.00022 6.5195 7.10553 6.70703 7.29301L10 10.586L13.293 7.29301C13.3853 7.19749 13.4956 7.12131 13.6176 7.0689C13.7396 7.01649 13.8709 6.98891 14.0036 6.98775C14.1364 6.9866 14.2681 7.0119 14.391 7.06218C14.5139 7.11246 14.6255 7.18672 14.7194 7.28061C14.8133 7.3745 14.8876 7.48615 14.9379 7.60905C14.9881 7.73195 15.0134 7.86363 15.0123 7.99641C15.0111 8.12919 14.9835 8.26041 14.9311 8.38241C14.8787 8.50441 14.8025 8.61476 14.707 8.707L10.707 12.707C10.5195 12.8945 10.2652 12.9998 10 12.9998C9.73487 12.9998 9.48056 12.8945 9.29303 12.707L5.29303 8.707C5.10556 8.51948 5.00024 8.26517 5.00024 8C5.00024 7.73484 5.10556 7.48053 5.29303 7.29301Z"
                                                                                                  fill="black"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <div style={{
                                                                                        position: 'absolute',
                                                                                        right: '0',
                                                                                        top: '0',
                                                                                        transition: '.1s linear',
                                                                                        transform: 'rotate(180deg)'
                                                                                    }}>
                                                                                        <svg width="20" height="20"
                                                                                             viewBox="0 0 20 20"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fillRule="evenodd"
                                                                                                  clipRule="evenodd"
                                                                                                  d="M5.29303 7.29301C5.48056 7.10553 5.73487 7.00022 6.00003 7.00022C6.26519 7.00022 6.5195 7.10553 6.70703 7.29301L10 10.586L13.293 7.29301C13.3853 7.19749 13.4956 7.12131 13.6176 7.0689C13.7396 7.01649 13.8709 6.98891 14.0036 6.98775C14.1364 6.9866 14.2681 7.0119 14.391 7.06218C14.5139 7.11246 14.6255 7.18672 14.7194 7.28061C14.8133 7.3745 14.8876 7.48615 14.9379 7.60905C14.9881 7.73195 15.0134 7.86363 15.0123 7.99641C15.0111 8.12919 14.9835 8.26041 14.9311 8.38241C14.8787 8.50441 14.8025 8.61476 14.707 8.707L10.707 12.707C10.5195 12.8945 10.2652 12.9998 10 12.9998C9.73487 12.9998 9.48056 12.8945 9.29303 12.707L5.29303 8.707C5.10556 8.51948 5.00024 8.26517 5.00024 8C5.00024 7.73484 5.10556 7.48053 5.29303 7.29301Z"
                                                                                                  fill="black"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                )
                                                                        )
                                                                    }
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {
                                                            salesPeriod === 'TODAY'
                                                                ?
                                                                (
                                                                    analyticsOrders?.itemSalesMapToday ?
                                                                        Object.keys(analyticsOrders?.itemSalesMapToday)?.length > 0
                                                                            ?
                                                                            salesToday.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td data-title="Item">{menuItemsName[item[0]]?.name[menu.i18n.defaultLang] || ''}</td>
                                                                                        <td data-title="Amount"
                                                                                            className="text-right">
                                                                                            {analyticsOrders?.itemSalesMapToday[item[0]]?.salesCount || 0}
                                                                                        </td>
                                                                                        <td data-title="Summ"
                                                                                            className="text-right">
                                                                                            {priceWithSpaceAndCurrency(analyticsOrders?.itemSalesMapToday[item[0]]?.salesSumm / 100 || 0, CURRENCY[menu.i18n.currency])}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            :
                                                                            <tr>
                                                                                <td colSpan={3}>{i18n.help_empty_orders()}</td>
                                                                            </tr>
                                                                        :
                                                                        <tr>
                                                                            <td colSpan={3}>{i18n.help_empty_orders()}</td>
                                                                        </tr>
                                                                )
                                                                :
                                                                (
                                                                    analyticsOrders.itemSalesMapTotal ?
                                                                        Object.keys(analyticsOrders?.itemSalesMapTotal)?.length > 0
                                                                            ?
                                                                            salesTotal.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td data-title="Item">{menuItemsName[item[0]]?.name[menu.i18n.defaultLang] || ''}</td>
                                                                                        <td data-title="Amount"
                                                                                            className="text-right">
                                                                                            {analyticsOrders?.itemSalesMapTotal[item[0]]?.salesCount || 0}
                                                                                        </td>
                                                                                        <td data-title="Summ"
                                                                                            className="text-right">
                                                                                            {priceWithSpaceAndCurrency(analyticsOrders?.itemSalesMapTotal[item[0]]?.salesSumm / 100 || 0, CURRENCY[menu.i18n.currency])}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            :
                                                                            <tr>
                                                                                <td colSpan={3}>{i18n.help_empty_orders()}</td>
                                                                            </tr>
                                                                        :
                                                                        <tr>
                                                                            <td colSpan={3}>{i18n.help_empty_orders()}</td>
                                                                        </tr>
                                                                )
                                                        }

                                                        </tbody>
                                                        <tfoot>
                                                        <tr>
                                                            <th colSpan={2}>{i18n.total()}</th>
                                                            <th className="text-right">
                                                                {
                                                                    salesPeriod === 'TODAY'
                                                                        ? priceWithSpaceAndCurrency((todaySalesSumm / 100) || 0, CURRENCY[menu.i18n.currency])
                                                                        :
                                                                        priceWithSpaceAndCurrency((totalSalesSumm / 100) || 0, CURRENCY[menu.i18n.currency])
                                                                }
                                                            </th>
                                                        </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="row">
                    <div className="col-lg-12" style={{width: '100%', display: "flex", gap: "20px"}}>
                        <div className="default-card mb-4" style={{position: 'relative', width: "50%"}}>
                            <h3>{i18n.top_10_dishes_by_segment()}</h3>
                            <Select
                                options={[
                                    {value: 'male 0-18', label: `${i18n.male()} 0-18`},
                                    {value: 'male 18-24', label: `${i18n.male()} 18-24`},
                                    {value: 'male 25-34', label: `${i18n.male()} 25-34`},
                                    {value: 'male 35-44', label: `${i18n.male()} 35-44`},
                                    {value: 'male 45-54', label: `${i18n.male()} 45-54`},
                                    {value: 'male 55-64', label: `${i18n.male()} 55-64`},
                                    {value: 'female 0-18', label: `${i18n.female()} 0-18`},
                                    {value: 'female 18-24', label: `${i18n.female()} 18-24`},
                                    {value: 'female 25-34', label: `${i18n.female()} 25-34`},
                                    {value: 'female 35-44', label: `${i18n.female()} 35-44`},
                                    {value: 'female 45-54', label: `${i18n.female()} 45-54`},
                                    {value: 'female 55-64', label: `${i18n.female()} 55-64`},
                                ]}
                                value={firstSelectedSegment}
                                onChange={(value) => setFirstSelectedSegment(value)}
                            />
                            {
                                (isLoadingDemog)
                                    ?
                                    <BlockLoader/>
                                    :
                                    <div id="pie-segment-1" style={{minHeight: '500px'}}></div>
                            }
                        </div>
                        <div className="default-card mb-4" style={{position: 'relative', width: '50%'}}>
                            <h3>{i18n.top_10_dishes_by_segment()}</h3>
                            <Select
                                options={[
                                    {value: 'male 0-18', label: `${i18n.male()} 0-18`},
                                    {value: 'male 18-24', label: `${i18n.male()} 18-24`},
                                    {value: 'male 25-34', label: `${i18n.male()} 25-34`},
                                    {value: 'male 35-44', label: `${i18n.male()} 35-44`},
                                    {value: 'male 45-54', label: `${i18n.male()} 45-54`},
                                    {value: 'male 55-64', label: `${i18n.male()} 55-64`},
                                    {value: 'female 0-18', label: `${i18n.female()} 0-18`},
                                    {value: 'female 18-24', label: `${i18n.female()} 18-24`},
                                    {value: 'female 25-34', label: `${i18n.female()} 25-34`},
                                    {value: 'female 35-44', label: `${i18n.female()} 35-44`},
                                    {value: 'female 45-54', label: `${i18n.female()} 45-54`},
                                    {value: 'female 55-64', label: `${i18n.female()} 55-64`},
                                ]}
                                value={secondSelectedSegment}
                                onChange={(value) => setSecondSelectedSegment(value)}
                            />
                            {
                                (isLoadingDemog)
                                    ?
                                    <BlockLoader/>
                                    :
                                    <div id="pie-segment-2" style={{minHeight: '500px'}}></div>
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-xl-8" style={{width: "100%", flex: "1", maxWidth: "100%"}}>
                        <div className="default-card mb-4" style={{position: 'relative'}}>
                            <h3>{i18n.visits_by_hours_by_segment()}</h3>
                            {
                                (isLoadingDemog)
                                    ?
                                    <BlockLoader/>
                                    :
                                    <div id="chartdiv" style={{minHeight: "700px"}}></div>
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-xl-8" style={{width: "100%", flex: "1", maxWidth: "100%"}}>
                        <div className="default-card mb-4" style={{position: 'relative'}}>
                            <h3>{i18n.general_portrait_of_customers()}</h3>
                            <div id="clusteredCustomerPortrait" style={{minHeight: "500px"}}/>
                        </div>
                    </div>
                </div>
            </main>
        </section>
    )
}

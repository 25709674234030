import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import React, { useEffect, useState } from "react";
import { Button } from "../../migration/shared/ui/Button";
import { InputDate } from "../../components/input/inputDate";

export function History() {
    const { api, i18n } = useGlobalContext();

    useEffect(() => {
        document.title = i18n.history();
    }, [i18n]);

    const [colaMonthlyReports, setColaMonthlyReports] = useState<Date>(new Date());

    const [historyPeriod, setHistoryPeriod] = useState<'WEEK' | 'MONTH' | '3MONTH' | 'PERIOD'>('WEEK');

    const [historyPeriodRange, setHistoryPeriodRange] = useState<{ startDate: Date, endDate: Date }>({
        startDate: new Date(),
        endDate: new Date()
    });

    const setHistoryPeriodRangeStartDate = (date: Date) => setHistoryPeriodRange({...historyPeriodRange, startDate: date});
    const setHistoryPeriodRangeEndDate = (date: Date) => setHistoryPeriodRange({...historyPeriodRange, endDate: date});

    const [loading, setLoading] = useState<boolean>(false);

    const getHistory = async (startDate: Date, endDate: Date) => {
        if (!startDate || !endDate) return;
        setLoading(true);
        const from = startDate.toISOString().split('T')[0];
        const to = endDate.toISOString().split('T')[0];
        await api.getHistory({ setLoading: setLoading }, {startDate: from, endDate: to})
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `(${from})to(${to}).xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.error('Ошибка при получении истории:', err);
            }).finally(() =>{
                setLoading(false);
        });
    }

    const getMonthlyColaReports = async (date: Date) => {
        if(!date) return;
        setLoading(true);
        await api.getMonthlyColaReport({ setLoading: setLoading }, date.toISOString().slice(0,7))
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `cola-${date.toDateString()}.xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.error('Ошибка при получении истории:', err);
            }).finally(() =>{
            setLoading(false);
        });
    }

    useEffect(() => {
        if (historyPeriod !== "PERIOD") {
            const today = new Date();
            let calculatedFromDate = new Date();

            switch (historyPeriod) {
                case "WEEK": {
                    calculatedFromDate.setDate(today.getDate() - 7)
                    break;
                }
                case "MONTH": {
                    calculatedFromDate.setMonth(today.getMonth() - 1)
                    break;
                }
                case "3MONTH": {
                    calculatedFromDate.setMonth(today.getMonth() - 3)
                    break;
                }
            }

            setHistoryPeriodRange({
                startDate: calculatedFromDate,
                endDate: today,
            });
        }
    }, [historyPeriod]);

    return (
        <section className="app__main">
            <main role="main" className="app__content">
                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.history()}</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8 col-xl-5">
                        <div className="default-card mb-4">
                            <h3>{i18n.user_history()}</h3>

                            <ul className="nav default-tabs mb-2 mb-lg-3">
                                <li className="default-tabs__item">
                                    <div
                                        className={`default-tabs__link${historyPeriod === 'WEEK' ? ' active' : ''}`}
                                        id="vdays7"
                                        style={{cursor: 'pointer', transition: '.1s linear'}}
                                        onClick={() => setHistoryPeriod('WEEK')}
                                    >
                                        {i18n.n7_days()}
                                    </div>
                                </li>
                                <li className="default-tabs__item">
                                    <div
                                        className={`default-tabs__link${historyPeriod === 'MONTH' ? ' active' : ''}`}
                                        id="vdays30"
                                        style={{cursor: 'pointer', transition: '.1s linear'}}
                                        onClick={() => setHistoryPeriod('MONTH')}
                                    >
                                        {i18n.n30_days()}
                                    </div>
                                </li>
                                <li className="default-tabs__item">
                                    <div
                                        className={`default-tabs__link${historyPeriod === '3MONTH' ? ' active' : ''}`}
                                        id="vdays90"
                                        style={{cursor: 'pointer', transition: '.1s linear'}}
                                        onClick={() => setHistoryPeriod('3MONTH')}
                                    >
                                        {i18n.n90_days()}
                                    </div>
                                </li>
                                <li className="default-tabs__item">
                                    <div
                                        className={`default-tabs__link${historyPeriod === 'PERIOD' ? ' active' : ''}`}
                                        id="vdays90"
                                        style={{cursor: 'pointer', transition: '.1s linear'}}
                                        onClick={() => setHistoryPeriod('PERIOD')}
                                    >
                                        {i18n.period()}
                                    </div>
                                </li>
                            </ul>
                            {historyPeriod === 'PERIOD' && (
                                <form
                                    className="form"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        gap: "10px"
                                    }}
                                >
                                    <InputDate
                                        label={i18n.from()}
                                        value={historyPeriodRange.startDate}
                                        start={historyPeriodRange.startDate}
                                        end={historyPeriodRange.endDate}
                                        onChange={setHistoryPeriodRangeStartDate}
                                        isValidate={true}/>
                                    <InputDate
                                        label={i18n.to()}
                                        value={historyPeriodRange.endDate}
                                        start={historyPeriodRange.startDate}
                                        end={historyPeriodRange.endDate}
                                        onChange={setHistoryPeriodRangeEndDate}
                                        isValidate={true}/>
                                </form>
                            )}
                            <Button text={i18n.download()}
                                    disabled={loading}
                                    loading={loading}
                                    onClick={() => {
                                        getHistory(historyPeriodRange.startDate, historyPeriodRange.endDate);
                                    }}
                            />
                        </div>
                    </div>
                    {/*<div className="col-lg-8 col-xl-5">*/}
                    {/*    <div className="default-card">*/}
                    {/*        <h3>{i18n.monthly_cola_report()}</h3>*/}

                    {/*        <InputDate*/}
                    {/*            label={""}*/}
                    {/*            value={colaMonthlyReports}*/}
                    {/*            onChange={setColaMonthlyReports}*/}
                    {/*            type={"month-year"}*/}
                    {/*            isValidate={true}/>*/}

                    {/*        <Button text={i18n.download()}*/}
                    {/*                disabled={loading}*/}
                    {/*                loading={loading}*/}
                    {/*                onClick={() => {*/}
                    {/*                    getMonthlyColaReports(colaMonthlyReports);*/}
                    {/*                }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </main>
        </section>
    );
}

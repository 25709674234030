import {ICurrentUserInfo, IOrgShortInfo, UserLogin} from "../models/user/userLogin";
import {ISlide, ISlideCreate, ISlideUpdate} from "../models/slider/slider";
import {ICurrentMenuInfoApiResult, Menu} from "../models/menu/menu";
import {ISection, ISectionCreate, ISectionShort, ISectionUpdate} from "../models/section/ISection";
import {ICategory, ICategoryCreate, ICategoryUpdate} from "../models/category/ICategory";
import {ICreateItemQuery, IEditItemQuery, IItemCreate, IItemUpdate} from "../models/item/IItem";
import {NavigateFunction} from "react-router-dom";
import IApiResult from "../models/api/api";
import {EditMenuTree, MenuTree} from "../models/menu/menuTree";
import {IOrder, IOrderItemChange, IOrderUpdateApi} from "../models/orders/order";
import {IMenuCreateApi} from "../models/menu/IMenuCreate";
import {IEditMenuInfo, IEditMenuInfoApi} from "../models/menuEditor/IEditMenuInfo";
import {IMenuOrgAttrsApi, IMenuOrgAttrsQuery} from "../models/settings/menu";
import {INotification, INotificationApi} from "../models/settings/notification";
import {IMenuI18nApi} from "../models/settings/i18n";
import {
    IOrganizationApi,
    IOrganizationUpdateApi,
    IOrgForCopyMenu,
    IOrgWithPrimaryUser,
    IStaffPageInfo
} from "../models/organizations/organization";
import {IOrganizationName} from "../models/org/name";
import {addNotification} from "../utils/toast/toast";
import {downloadBlob} from "../utils/scripts";
import {ILinkGroup} from "../models/link/link";
import {OrgSettings} from "../models/org/orgSettings";
import {ISettingScripts} from "../models/settings/scripts";
import {LangKey} from "../hooks/globalContext";
import {
    NltxDuringPeriodReq,
    IAnalyticsCustomerEvents,
    IAnalyticsOrders,
    IMenuItemsName, NltxDemog, IMenuStats
} from "../models/nltx/IAnalytics";
import {ICreateCustomPage, IGetCustomPages, IUpdateCustomPage} from "../models/customPage/customPage";
import {MenuStylize} from "../models/menuStylize/menuStylize";
import {iikoOrganizationBinding, iikoOrganizations} from "../models/iiko/org";
import {iikoProductBinding, iikoProductCategories, iikoProducts} from "../models/iiko/product";
import {IIKOCompany} from "../models/iiko/company";
import {Placements} from "../models/placements/placements";
import {createOrderSettings, getOrderSettings, iikoTerminalGroupsWithPaymentTypes} from "../models/iiko/settings";
import {Bespoke} from "../models/menuStylize/bespoke";
import {IStaffUsers} from "../models/user/staff";
import {TXT} from "../txtMap/embedded/txt";
import {OneVision} from "../models/payments/oneVision";
import {IIikoTableBinding, ITables} from "../models/iiko/tables";
import {ICustomer} from "../models/orders/customer";
import {IKaspiPaymentDeviceBinding, IKaspiPaymentSettings, IKaspiPaymentTradePoint} from "../models/kaspi/kaspi";
import {IMenuItemMedia} from "../models/item/itemMedia";
import {IReportsQuery, IReportsResponse} from "../models/reports/reports";
import {IOrderSupItem} from "../models/orderSupItem/orderSupItem";
import {I18nString} from "../models/lang/i18nString";
import {IStopList} from "../models/iiko/stopList";
import {DomainSettings} from "../models/requests/domainSettings";
import {WebhookSettings} from "../migration/entities/webhookSettings";
import {JetPay} from "../models/payments/jetpay";
import {AdditionalSettings} from "../models/requests/additionalSettings";


const URL = process.env.REACT_APP_API_URL
const NLTXURL = process.env.REACT_APP_NLTX_API_URL;

interface IApiContext {
    controller?: AbortController,
    setLoading: (loading: boolean) => void,
}

export default class KamiService {
    navigateTo: NavigateFunction
    lang: LangKey
    i18n: TXT

    constructor(navigateTo: NavigateFunction, lang: LangKey, i18n: TXT) {
        this.navigateTo = navigateTo
        this.lang = lang
        this.i18n = i18n
    }

    private async handleStatusError(ctx: IApiContext, resp: Promise<Response>, success: string, error: string): Promise<IApiResult<any>> {
        let res = await resp
        let loginPage = window.location.pathname.includes("login")
        let changePasswordPage = window.location.pathname.includes("change-password")
        if (res.status === 403 || res.status === 401) {
            window.localStorage.clear()
        }

        // json
        let resJson: IApiResult<any> = await res.json()

        if (res.status === 401) {
            ctx.setLoading(false)
            addNotification({type: "warning", message: error})

            if (!loginPage && !changePasswordPage && resJson.error) {
                this.navigateTo("/login")
            }

            return resJson.data
        }
        if (res.status === 500) {
            ctx.setLoading(false)
            if (resJson.error) {
                addNotification({type: "danger", message: resJson.error.message})
            } else {
                addNotification({type: "danger", message: this.i18n.server_is_not_available()})
            }
            return resJson.data
        } else {
            if (resJson.success) {
                if (success) {
                    addNotification({type: "success", message: success})
                }
            } else {
                if (resJson.error) {
                    addNotification({type: "warning", message: resJson.error.message})
                } else {
                    addNotification({type: "warning", message: error})
                }
            }
        }

        ctx.setLoading(false)
        return resJson
    }

    private fetchWithTimeout(ctx: IApiContext, url: string, options: RequestInit, timeout: number = 120000): Promise<Response> {
        ctx.setLoading(true)
        if (!ctx.controller) {
            ctx.controller = new AbortController()
        }
        const timeoutId = setTimeout(() => ctx?.controller?.abort(), timeout);
        return Promise.race<Response>([
            fetch(url, {
                ...options,
                signal: ctx.controller.signal
            }),
            new Promise<Response>((_, reject) =>
                setTimeout(() => {
                    return reject(new Error('timeout'))
                }, timeout)
            )
        ]).finally(() => {
            clearTimeout(timeoutId)
        })
    }

    async login(ctx: IApiContext, user: UserLogin): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/login`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                "username": user?.email,
                "password": user?.password
            })
        })
        return this.handleStatusError(ctx, res, this.i18n.login_success(), this.i18n.login_failed())
    }

    async logout(ctx: IApiContext): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/logout`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({})
        });
        window.localStorage.clear()
        return this.handleStatusError(ctx, res, this.i18n.logout_success(), this.i18n.logout_failed())
    }

    async getCurrentUser(ctx: IApiContext): Promise<IApiResult<ICurrentUserInfo>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/current-user-info`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({})
        })
        return this.handleStatusError(ctx, res, "", this.i18n.get_user_info_failed())
    }

    async getCurrentMenuInfo(ctx: IApiContext, menuId: string,): Promise<IApiResult<ICurrentMenuInfoApiResult>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/current-menu-info`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({menuId: menuId})
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_menu_info_failed())
    }

    //==================================================================================================================
    //========================================== Slides ================================================================
    //==================================================================================================================
    // Get all slides
    async getSlider(ctx: IApiContext, menuId: string): Promise<IApiResult<{ slides: ISlide[] }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-slides`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_slides_failed())
    }

    // Get slide by index
    async getSlide(ctx: IApiContext, id: string, menuId: string): Promise<IApiResult<ISlide>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-slide-by-id`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id,
                menuId: menuId
            })
        });

        return this.handleStatusError(ctx, res, "", this.i18n.get_slide_failed())
    }

    // save slider sort
    async saveSliderSort(ctx: IApiContext, slides: ISlide[], menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/sort-menu-slider`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                newOrder: slides.map((s) => s.id)
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.save_slider_sort_success(), this.i18n.save_slider_sort_failed())
    }

    // Creat new slide
    async createSlide(ctx: IApiContext, slide: ISlideCreate): Promise<IApiResult<{ id: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/add-menu-slide`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(slide)
        });
        return this.handleStatusError(ctx, res, this.i18n.create_slide_success(), this.i18n.create_slide_failed())
    }

    // Update slide
    async updateSlide(ctx: IApiContext, slide: ISlideUpdate): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-slide`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(slide)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_slide_success(), this.i18n.update_slide_failed())
    }

    // Delete slide
    async deleteSlide(ctx: IApiContext, id: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-menu-slide`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id,
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_slide_success(), this.i18n.delete_slide_failed())
    }

    // Upload slide image
    async uploadSlideFile(ctx: IApiContext, file: File): Promise<IApiResult<{ newFilename: string }>> {
        const formData = new FormData();
        formData.append("file", file);
        const res = this.fetchWithTimeout(ctx, `${URL}/upload/menu-slide-img`, {
            headers: {
                'X-Accept-User-Lang': this.lang
            },
            method: 'post',
            mode: "cors",
            credentials: "include",
            body: formData
        });
        return this.handleStatusError(ctx, res, this.i18n.upload_slide_image_success(), this.i18n.upload_slide_image_failed())
    }

    //==================================================================================================================
    //========================================== Sections ==============================================================
    //==================================================================================================================
    // Get all sections
    async getSections(ctx: IApiContext, menuId: string): Promise<IApiResult<{ sections: ISectionShort[] }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-sections`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_sections_failed())
    }

    // get section by id
    async getSectionById(ctx: IApiContext, sectionId: string, menuId: string,): Promise<IApiResult<ISection>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-section-by-id`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                sectionId: sectionId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_section_failed())
    }

    // create new section
    async createSection(ctx: IApiContext, section: ISectionCreate): Promise<IApiResult<{ id: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-menu-section`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(section)
        });
        return this.handleStatusError(ctx, res, this.i18n.create_section_success(), this.i18n.create_section_failed())
    }

    // delete section
    async deleteSection(ctx: IApiContext, sectionId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-menu-section`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: sectionId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_section_success(), this.i18n.delete_section_failed())
    }

    // update section
    async updateSection(ctx: IApiContext, section: ISectionUpdate): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-section`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(section)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_section_success(), this.i18n.update_section_failed())
    }

    // hide section
    async hideSection(ctx: IApiContext, sectionId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/hide-menu-section`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: sectionId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.hide_section_success(), this.i18n.hide_section_failed())
    }

    // reveal section
    async revealSection(ctx: IApiContext, sectionId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/reveal-menu-section`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: sectionId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.reveal_section_success(), this.i18n.reveal_section_failed())
    }

    // save section image
    async saveSectionImage(ctx: IApiContext, file: File): Promise<IApiResult<{ newFilename: string }>> {
        const formData = new FormData();
        formData.append("file", file);
        const res = this.fetchWithTimeout(ctx, `${URL}/upload/menu-section-img`, {
            method: 'post',
            mode: "cors",
            headers: {
                'X-Accept-User-Lang': this.lang
            },
            credentials: "include",
            body: formData
        });
        return this.handleStatusError(ctx, res, this.i18n.upload_section_image_success(), this.i18n.upload_section_image_failed())
    }

    //==================================================================================================================
    //========================================== Category ==============================================================
    //==================================================================================================================
    // get category by id
    async getCategoryById(ctx: IApiContext, categoryId: string, menuId: string,): Promise<IApiResult<ICategory>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-category-by-id`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                categoryId: categoryId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_category_failed())
    }

    // create new category
    async createCategory(ctx: IApiContext, category: ICategoryCreate): Promise<IApiResult<{ id: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-menu-category`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(category)
        });
        return this.handleStatusError(ctx, res, this.i18n.create_category_success(), this.i18n.create_category_failed())
    }

    // delete category
    async deleteCategory(ctx: IApiContext, categoryId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-menu-category`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: categoryId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_category_success(), this.i18n.delete_category_failed())
    }

    // update category
    async updateCategory(ctx: IApiContext, category: ICategoryUpdate): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-category`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(category)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_category_success(), this.i18n.update_category_failed())
    }

    // hide category
    async hideCategory(ctx: IApiContext, categoryId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/hide-menu-category`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: categoryId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.hide_category_success(), this.i18n.hide_category_failed())
    }

    // reveal category
    async revealCategory(ctx: IApiContext, categoryId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/reveal-menu-category`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: categoryId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.reveal_category_success(), this.i18n.reveal_category_failed())
    }

    //==================================================================================================================
    //========================================== Item ==================================================================
    //==================================================================================================================

    async getItemById(ctx: IApiContext, itemId: string, menuId: string,): Promise<IApiResult<IEditItemQuery>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-item-by-id`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                itemId: itemId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_item_failed())
    }

    async getMenuStructureForItemCreate(ctx: IApiContext, menuId: string,): Promise<IApiResult<ICreateItemQuery>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-struct-for-item-create`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_menu_structure_for_item_create_failed())
    }

    async createItem(ctx: IApiContext, item: IItemCreate): Promise<IApiResult<{ id: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-menu-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(item)
        });
        return this.handleStatusError(ctx, res, this.i18n.create_item_success(), this.i18n.create_item_failed())
    }

    async updateItem(ctx: IApiContext, item: IItemUpdate): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(item)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_item_success(), this.i18n.update_item_failed())
    }

    async deleteItem(ctx: IApiContext, itemId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-menu-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: itemId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_item_success(), this.i18n.delete_item_failed())
    }

    async hideItem(ctx: IApiContext, itemId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/hide-menu-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: itemId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.hide_item_success(), this.i18n.hide_item_failed())
    }

    async revealItem(ctx: IApiContext, itemId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/reveal-menu-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: itemId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.reveal_item_success(), this.i18n.reveal_item_failed())
    }

    async turnOnItem(ctx: IApiContext, itemId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/turn-on-menu-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: itemId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.turn_on_item_success(), this.i18n.turn_on_item_failed())
    }

    async turnOffItem(ctx: IApiContext, itemId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/turn-off-menu-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: itemId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.turn_off_item_success(), this.i18n.turn_off_item_failed())
    }

    async saveItemImage(ctx: IApiContext, file: File): Promise<IApiResult<{ newFilename: string }>> {
        const formData = new FormData();
        formData.append("file", file);
        const res = this.fetchWithTimeout(ctx, `${URL}/upload/menu-item-img`, {
            method: 'post',
            mode: "cors",
            headers: {
                'X-Accept-User-Lang': this.lang
            },
            credentials: "include",
            body: formData
        });
        return this.handleStatusError(ctx, res, this.i18n.upload_image_success(), this.i18n.upload_image_failed())
    }


    //==================================================================================================================
    //========================================== Menu ==================================================================
    //==================================================================================================================
    async createMenu(ctx: IApiContext, menu: IMenuCreateApi): Promise<IApiResult<{ newMenuId: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-menu`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(menu)
        });
        return this.handleStatusError(ctx, res, this.i18n.create_menu_success(), this.i18n.create_menu_failed())
    }

    async getMenuOrgAttrs(ctx: IApiContext, menuId: string,): Promise<IApiResult<IEditMenuInfo>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-org-attrs`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_menu_org_attrs_failed())
    }

    async updateMenuOrgAttrs(ctx: IApiContext, menu: IEditMenuInfoApi): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-org-attrs`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(menu)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_menu_org_attrs_success(), this.i18n.update_menu_org_attrs_failed())
    }

    async getMenuSettingsOrderBase(ctx: IApiContext, menuId: string,): Promise<IApiResult<IMenuOrgAttrsQuery>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-settings-order-base`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_menu_settings_order_base_failed())
    }

    async updateMenuSettingsOrderBase(ctx: IApiContext, menu: IMenuOrgAttrsApi): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-settings-order-base`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(menu)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_menu_settings_order_base_success(), this.i18n.update_menu_settings_order_base_failed())
    }

    async getMenuStruct(ctx: IApiContext, menuId: string,): Promise<IApiResult<Menu>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-items`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_menu_struct_failed())
    }

    async getMenuStructForMenuPage(ctx: IApiContext, menuId: string,): Promise<IApiResult<MenuTree>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-struct-for-menu-page`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
            })
        });

        return this.handleStatusError(ctx, res, "", this.i18n.get_menu_struct_failed())
    }

    async archiveMenu(ctx: IApiContext, menuId: string, orgId: string): Promise<IApiResult<{ defaultId: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/archive-menu`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                organizationId: orgId,
                menuId: menuId,
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_menu_success(), this.i18n.delete_menu_failed())
    }

    //save menu sort
    async saveMenuSort(ctx: IApiContext, menuId: string, tree: EditMenuTree): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/sort-menu-by-tree`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                tree: tree
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.save_menu_sort_success(), this.i18n.save_menu_sort_failed())
    }

    async saveMenuOrgAttrsLogoImage(ctx: IApiContext, file: File): Promise<IApiResult<{ newFilename: string }>> {
        const formData = new FormData();
        formData.append("file", file);
        const res = this.fetchWithTimeout(ctx, `${URL}/upload/menu-org-attrs-logo`, {
            method: 'post',
            mode: "cors",
            headers: {
                'X-Accept-User-Lang': this.lang
            },
            credentials: "include",
            body: formData
        });
        return this.handleStatusError(ctx, res, this.i18n.upload_menu_org_attrs_logo_success(), this.i18n.upload_menu_org_attrs_logo_failed())
    }

    async saveLogoImage(ctx: IApiContext, file: File): Promise<IApiResult<{ newFilename: string }>> {
        const formData = new FormData();
        formData.append("file", file);
        const res = this.fetchWithTimeout(ctx, `${URL}/upload/org-logo`, {
            method: 'post',
            mode: "cors",
            headers: {
                'X-Accept-User-Lang': this.lang
            },
            credentials: "include",
            body: formData
        });
        return this.handleStatusError(ctx, res, this.i18n.upload_org_logo_success(), this.i18n.upload_org_logo_failed())
    }

    async saveBackgroundImage(ctx: IApiContext, file: File): Promise<IApiResult<{ newFilename: string }>> {
        const formData = new FormData();
        formData.append("file", file);
        const res = this.fetchWithTimeout(ctx, `${URL}/upload/menu-org-attrs-background-img`, {
            method: 'post',
            mode: "cors",
            headers: {
                'X-Accept-User-Lang': this.lang
            },
            credentials: "include",
            body: formData
        });
        return this.handleStatusError(ctx, res, this.i18n.upload_menu_org_attrs_background_img_success(), this.i18n.upload_menu_org_attrs_background_img_failed())
    }


    //==================================================================================================================
    //========================================== Order =================================================================
    //==================================================================================================================

    async getOrders(ctx: IApiContext, menuId: string, limit: number, skip: number, find: string,): Promise<IApiResult<{
        orders: IOrder[],
        count: number
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-orders`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                limit: limit,
                skip: skip,
                find: find
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_orders_failed())
    }

    async getOrderById(ctx: IApiContext, menuId: string, orderId: string,): Promise<IApiResult<IOrder>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-order-by-id`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                orderId: orderId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_order_failed())
    }

    //==================================================================================================================
    //========================================== Settings ==============================================================
    //==================================================================================================================
    async getOrderNotificationSettings(ctx: IApiContext, menuId: string,): Promise<IApiResult<INotification>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/order-notification-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_order_notification_settings_failed())
    }

    async switchTelegramMenuSettingsOrderNotification(ctx: IApiContext, menuId: string, toggle: boolean): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/switch-telegram-menu-settings-order-notification`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                toggle: toggle
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.switch_telegram_menu_settings_order_notification_success(), this.i18n.switch_telegram_menu_settings_order_notification_failed())
    }

    async disableUserMenuSettingsOrderNotification(ctx: IApiContext, menuId: string, chatId: number): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/disable-user-menu-settings-order-notification`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                chatId: chatId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.disable_user_menu_settings_order_notification_success(), this.i18n.disable_user_menu_settings_order_notification_failed())
    }

    async enableUserMenuSettingsOrderNotification(ctx: IApiContext, menuId: string, chatId: number): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/enable-user-menu-settings-order-notification`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                chatId: chatId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.enable_user_menu_settings_order_notification_success(), this.i18n.enable_user_menu_settings_order_notification_failed())
    }


    async updateMenuI18n(ctx: IApiContext, i18n: IMenuI18nApi): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-i18n`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(i18n)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_menu_main_settings_success(), this.i18n.update_menu_main_settings_failed())
    }

    async saveLinkGroups(ctx: IApiContext, menuId: string, linkGroups: ILinkGroup[]): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-link-groups`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                linkGroups: linkGroups
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.save_link_groups_success(), this.i18n.save_link_groups_failed())
    }

    async getLinkGroups(ctx: IApiContext, menuId: string,): Promise<IApiResult<{ linkGroups: ILinkGroup[] | null }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-link-groups`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_link_groups_failed())
    }

    //==================================================================================================================
    //========================================== Staff =================================================================
    //==================================================================================================================

    async getOrgWithPrimaryUser(ctx: IApiContext, orgId: string,): Promise<IApiResult<IOrgWithPrimaryUser>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/organization-with-primary-user`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_org_with_primary_user_failed())
    }

    async getStaff(ctx: IApiContext, limit: number, skip: number, find: string,): Promise<IApiResult<IStaffUsers>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/staff`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                limit: limit,
                skip: skip,
                find: find
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_staff_failed())
    }

    async createOrganization(ctx: IApiContext, org: IOrganizationApi): Promise<IApiResult<{
        newOrgId: string,
        newUserAccountId: string
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/register-new-org-with-primary-user`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(org)
        });
        return this.handleStatusError(ctx, res, this.i18n.create_organization_success(), this.i18n.create_organization_failed())
    }

    async updateOrganization(ctx: IApiContext, org: IOrganizationUpdateApi): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-org-with-primary-user`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(org)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_organization_success(), this.i18n.update_organization_failed())
    }

    async getOrganizations(ctx: IApiContext, limit: number, skip: number, find: string,): Promise<IApiResult<IStaffPageInfo>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/organizations`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                limit: limit,
                skip: skip,
                find: find
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_organizations_failed())
    }

    async getOrganizationNameByIds(ctx: IApiContext, ids: string[],): Promise<IApiResult<{
        organizationNames: IOrganizationName[]
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/organization-name-by-ids`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                ids: ids
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_organization_name_by_ids_failed())
    }

    async getOrganizationById(ctx: IApiContext, id: string,): Promise<IApiResult<IOrgShortInfo>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/organization-by-id`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_organization_by_id_failed())
    }

    async saveOrgSettings(ctx: IApiContext, org: OrgSettings, orgId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-org-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                name: org.name,
                address: org.address,
                logoImg: org.logoImg,
                subdomain: org.subdomain,
                defaultLang: org.defaultLang,
                orgId: orgId,
                customDomain: org.customDomain,
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.save_org_settings_success(), this.i18n.save_org_settings_failed())
    }

    async saveScripts(ctx: IApiContext, scripts: ISettingScripts, menuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-custom-web`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                ...scripts
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.save_scripts_success(), this.i18n.save_scripts_failed())
    }

    async getScripts(ctx: IApiContext, menuId: string,): Promise<IApiResult<{ customWeb: ISettingScripts }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-settings-scripts`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_scripts_failed())
    }

    async saveMenuNameAndLink(ctx: IApiContext, menu: {
        name: string,
        link: string,
        menuId: string
    }): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-menu-name-and-link`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(menu)
        });
        return this.handleStatusError(ctx, res, this.i18n.save_menu_name_and_link_success(), this.i18n.save_menu_name_and_link_failed())
    }

    async createStaffUser(ctx: IApiContext, userName: string, email: string, lang: LangKey): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-staff-user`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                userName: userName,
                email: email,
                lang: lang
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.create_staff_user_success(), this.i18n.create_staff_user_failed())
    }

    async disableUser(ctx: IApiContext, userAccountId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/disable-user`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                userAccountId: userAccountId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.disable_user_account_success(), this.i18n.disable_user_account_failed())
    }

    async enableUser(ctx: IApiContext, userAccountId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/enable-user`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                userAccountId: userAccountId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.enable_user_account_success(), this.i18n.enable_user_account_failed())
    }

    async resetPasswordStaffUser(ctx: IApiContext, userAccountId: string): Promise<IApiResult<{
        newTmpPassword: string
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/reset-password-for-user`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                userAccountId: userAccountId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.reset_password_staff_user_success(), this.i18n.reset_password_staff_user_failed())
    }

    // Published

    async publishMenu(ctx: IApiContext, menuId: string, orgId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/publish-menu`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                orgId: orgId
            })
        })
        return this.handleStatusError(ctx, res, this.i18n.publish_menu_success(), this.i18n.publish_menu_failed())
    }

    //==================================================================================================================
    //========================================== NLTX  =================================================================
    //==================================================================================================================

    async getNltxOrders(ctx: IApiContext, menuId: string,): Promise<IApiResult<IAnalyticsOrders>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/analytics-data-orders`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        })
        return res.then(res => res.json())
    }

    async getNltxCustomerEvents(ctx: IApiContext, menuId: string,): Promise<IApiResult<IAnalyticsCustomerEvents>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/analytics-data-customer-events-clickhouse`, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        })
        return res.then(res => res.json())
    }

    async getNltxOrdersDuringPeriod(ctx: IApiContext, request: NltxDuringPeriodReq): Promise<IApiResult<IAnalyticsOrders>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/analytics-data-orders-during-period`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(request)
        })
        return res.then(res => res.json())
    }

    async getNltxCustomerEventsDuringPeriod(ctx: IApiContext, request: NltxDuringPeriodReq): Promise<IApiResult<IAnalyticsCustomerEvents>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/analytics-data-customer-events-clickhouse-during-period`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(request)
        })
        return res.then(res => res.json())
    }

    async getNltxDemog(ctx: IApiContext, request: {menuId: string}): Promise<IApiResult<NltxDemog>> {
        const res = this.fetchWithTimeout(ctx, `${NLTXURL}/demog-stats`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(request)
        })
        return res.then(res => res.json())
    }

    async getMenuItemsName(ctx: IApiContext, menuId: string,): Promise<IApiResult<IMenuItemsName>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-items-name`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_items_names_failed())
    }

    async downloadQrCode(ctx: IApiContext, menuId: string): Promise<IApiResult<{}>> {
        const res = await this.fetchWithTimeout(ctx, `${URL}/download-qr`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        if (res.headers.get('content-type') === 'application/json') {
            return this.handleStatusError(ctx, Promise.reject(res), "", this.i18n.download_qr_code_failed())
        }
        let filename = (res.headers.get('Content-Disposition') || "").split('filename=')[1].split(';')[0];
        downloadBlob(filename, await res.blob())
        return {
            "requestId": "string",
            "success": true,
            "error": undefined,
            "data": {}
        }
    }

    async downloadQrCodeForPlacements(ctx: IApiContext, menuId: string) {
        const res = await this.fetchWithTimeout(ctx, `${URL}/download-qr-for-placements`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        if (res.headers.get('content-type') === 'application/json') {
            return this.handleStatusError(ctx, Promise.reject(res), "", this.i18n.download_qr_code_failed())
        }
        let filename = (res.headers.get('Content-Disposition') || "").split('filename=')[1].split(';')[0];
        downloadBlob(filename, await res.blob())
        return {
            "requestId": "string",
            "success": true,
            "error": undefined,
            "data": {}
        }
    }

    async changePassword(ctx: IApiContext, pass: {
        oldPassword: string, newPassword: string
        reNewPass: string, userId: string
    }): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/change-password`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                userAccountId: pass.userId,
                old: pass.oldPassword,
                new: pass.newPassword,
                repeatNew: pass.reNewPass
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.change_password_success(), this.i18n.change_password_failed())
    }

    async resetPasswordForUser(ctx: IApiContext, userId: string): Promise<IApiResult<{ newTmpPassword: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/reset-password-for-user`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                userAccountId: userId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.reset_password_success(), this.i18n.reset_password_failed())
    }

    async getOrderItems(ctx: IApiContext, menuId: string,): Promise<IApiResult<{
        MenuOrderItems: IOrderItemChange[]
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-order-items`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_order_items_failed())
    }

    async updateOrderManualy(ctx: IApiContext, order: IOrderUpdateApi): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-order-manually`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(order)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_order_success(), this.i18n.update_order_failed())
    }

    async CancelOrder(ctx: IApiContext, menuId: string, orderId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/cancel-order`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                orderId: orderId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.cancel_order_success(), this.i18n.cancel_order_failed())
    }

    async approveOrder(ctx: IApiContext, menuId: string, orderId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/approve-order`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                orderId: orderId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.approve_order_success(), this.i18n.approve_order_failed())
    }

    //Custom Pages
    async getCustomPages(ctx: IApiContext, menuId: string, orgId: string,): Promise<IApiResult<IGetCustomPages>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-custom-pages`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                orgId: orgId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_custom_pages_failed())
    }

    async createCustomPage(ctx: IApiContext, customPage: ICreateCustomPage): Promise<IApiResult<{ id: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-custom-page`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(customPage)
        });
        return this.handleStatusError(ctx, res, this.i18n.create_custom_page_success(), this.i18n.create_custom_page_failed())
    }

    async updateCustomPage(ctx: IApiContext, customPage: IUpdateCustomPage): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-custom-page`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(customPage)
        });
        return this.handleStatusError(ctx, res, this.i18n.update_custom_page_success(), this.i18n.update_custom_page_failed())
    }

    async deleteCustomPage(ctx: IApiContext, customPageId: string, orgId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-custom-page`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: customPageId,
                orgId: orgId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_custom_page_success(), this.i18n.delete_custom_page_failed())
    }

    async sortMenuCustomPages(ctx: IApiContext, menuId: string, customPageIds: string[]): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/sort-menu-custom-pages`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                newOrder: customPageIds
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.sort_custom_pages_success(), this.i18n.sort_custom_pages_failed())
    }

    async saveCustomPageImage(ctx: IApiContext, image: File): Promise<IApiResult<{ newFilename: string }>> {
        const formData = new FormData()
        formData.append('file', image)
        const res = this.fetchWithTimeout(ctx, `${URL}/upload/custom-page-img`, {
            method: 'post',
            headers: {
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: formData
        });
        return this.handleStatusError(ctx, res, this.i18n.save_custom_page_image_success(), this.i18n.save_custom_page_image_failed())
    }

    async getMenuStylize(ctx: IApiContext, menuId: string,): Promise<IApiResult<MenuStylize | null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-stylize`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_menu_stylize_failed())
    }

    async updateMenuStylize(ctx: IApiContext, menuId: string, stylize: MenuStylize): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/save-styling`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                styling: stylize
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.update_menu_stylize_success(), this.i18n.update_menu_stylize_failed())
    }

    async copyTotalMenus(ctx: IApiContext, srcMenuId: string, dstMenuId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/copy-total-menus`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                srcMenuId: srcMenuId,
                dstMenuId: dstMenuId
            })
        })
        return this.handleStatusError(ctx, res, this.i18n.copy_total_menus_success(), this.i18n.copy_total_menus_failed())
    }

    async disableOrganization(ctx: IApiContext, orgId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/disable-organization`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.disable_organization_success(), this.i18n.disable_organization_failed())
    }

    async enableOrganization(ctx: IApiContext, orgId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/enable-organization`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.enable_organization_success(), this.i18n.enable_organization_failed())
    }


    //IIKO API org
    async getIikoCompanyBinding(ctx: IApiContext, organizationId: string,): Promise<IApiResult<IIKOCompany | null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-company-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                organizationId: organizationId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_orgs_failed())
    }

    async createIIkoCompanyBinding(ctx: IApiContext, organizationId: string, apiKey: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-iiko-company-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                organizationId: organizationId,
                apiKey: apiKey
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.create_iiko_org_binding_success(), this.i18n.create_iiko_org_binding_failed())
    }

    async deleteIIkoCompanyBinding(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-iiko-company-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_iiko_org_binding_success(), this.i18n.delete_iiko_org_binding_failed())
    }

    //IIKO API menu
    async getIIkoOrganizationBinding(ctx: IApiContext, menuId: string,): Promise<IApiResult<iikoOrganizationBinding | null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-organization-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_org_failed())
    }

    async getIIkoOrganizations(ctx: IApiContext, orgId: string,): Promise<IApiResult<iikoOrganizations | null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-organizations`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                organizationId: orgId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_org_failed())
    }

    async createIIkoOrganizationBinding(ctx: IApiContext, menuId: string, iikoOrganizationId: string, isAutoSync: boolean): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-iiko-organization-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                iikoOrganizationId: iikoOrganizationId,
                isAutoSync: isAutoSync
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.iiko_org_bind_to_menu_success(), this.i18n.iiko_org_bind_to_menu_failed())
    }

    async deleteIIkoOrganizationBinding(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-iiko-organization-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_iiko_org_binding_to_menu_success(), this.i18n.delete_iiko_org_binding_to_menu_failed())
    }

    //IIKO API product
    async getIIkoProducts(ctx: IApiContext, menuId: string,): Promise<IApiResult<iikoProducts>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-products`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_products_failed())
    }

    async getIIkoProductBinding(ctx: IApiContext, menuId: string, itemId: string,): Promise<IApiResult<iikoProductBinding | null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-product-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                itemId: itemId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_product_binding_failed())
    }

    async createIIkoProductBinding(ctx: IApiContext, menuId: string, itemId: string, iikoProductId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-iiko-product-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                itemId: itemId,
                iikoProductId: iikoProductId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.create_iiko_product_binding_success(), this.i18n.create_iiko_product_binding_failed())
    }

    async deleteIIkoProductBinding(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-iiko-product-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_iiko_product_binding_success(), this.i18n.delete_iiko_product_binding_failed())
    }

    async getIIkoProductCategories(ctx: IApiContext, menuId: string,): Promise<IApiResult<iikoProductCategories | null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-product-categories`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_product_categories_failed())
    }

    async copyIIkoProducts(ctx: IApiContext, menuId: string, iikoCategoryIds: string[], iikoProductIds: string[]): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/copy-iiko-products`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                iikoCategoryIds: iikoCategoryIds,
                iikoProductIds: iikoProductIds
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.copy_iiko_products_success(), this.i18n.copy_iiko_products_failed())
    }

    //Placements
    async getPlacements(ctx: IApiContext, menuId: string,): Promise<IApiResult<Placements>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/placements`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_placements_failed())
    }

    async savePlacements(ctx: IApiContext, menuId: string, placements: Placements): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/save-placements`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                ...placements,
                menuId: menuId,
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.save_placements_success(), this.i18n.save_placements_failed())
    }

    //IIKO SETTINGS
    async getIIkoTerminalGroupsWithPaymentTypes(ctx: IApiContext, menuId: string,): Promise<IApiResult<iikoTerminalGroupsWithPaymentTypes | null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-terminal-groups-with-payment-types`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_terminal_groups_failed())
    }

    async getIIkoSettings(ctx: IApiContext, menuId: string,): Promise<IApiResult<getOrderSettings | null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-order-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_settings_failed())
    }

    async saveIIkoSettings(ctx: IApiContext, settings: createOrderSettings): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-iiko-order-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(settings)
        });
        return this.handleStatusError(ctx, res, this.i18n.save_iiko_settings_success(), this.i18n.save_iiko_settings_failed())
    }

    async deleteIIkoSettings(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-iiko-order-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_iiko_settings_success(), this.i18n.delete_iiko_settings_failed())
    }

    // Bespoke
    async saveMenuBespoke(ctx: IApiContext, menuId: string, bespoke: Bespoke): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/save-menu-bespoke`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                ...bespoke
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.save_bespoke_success(), this.i18n.save_bespoke_failed())
    }

    async getMenuBespoke(ctx: IApiContext, menuId: string,): Promise<IApiResult<Bespoke>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-bespoke`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_bespoke_failed())
    }

    //==================================================================================================
    //==========================================  Payment  ============================================
    //==================================================================================================
    async getOneVisionSettings(ctx: IApiContext, orgId: string,): Promise<IApiResult<{
        apiKey: string,
        encryptedSecretKey: string
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/one-vision-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_onevision_settings_failed())
    }

    async saveOneVisionSettings(ctx: IApiContext, settings: OneVision): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/save-one-vision-payment-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(settings)
        });
        return this.handleStatusError(ctx, res, this.i18n.save_onevision_settings_success(), this.i18n.save_onevision_settings_failed())
    }

    async encodePaymentKey(ctx: IApiContext, secretKey: string): Promise<IApiResult<{ encryptedSecretKey: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/encode-payment-key`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                secretKey: secretKey
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.encode_payment_key_failed())
    }

    async getMenuPaymentSettings(ctx: IApiContext, orgId: string, menuId: string,): Promise<IApiResult<{
        paymentMethods: string[] | null,
        activatedPaymentMethods: string[] | null,
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-settings-order-payment`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId,
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_payment_settings_failed())
    }

    async saveMenuSettingsOrderPayment(ctx: IApiContext, menuId: string, paymentMethods: string[]): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/save-menu-settings-order-payment`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                paymentMethods: paymentMethods
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.save_payment_settings_success(), this.i18n.save_payment_settings_failed())
    }

    async getIikoTables(ctx: IApiContext, menuId: string,): Promise<IApiResult<ITables>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-tables`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_tables_failed())
    }

    async getIikoTableBindings(ctx: IApiContext, menuId: string,): Promise<IApiResult<IIikoTableBinding[] | null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-table-bindings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_iiko_table_binding_failed())
    }

    async createIikoTableBinding(ctx: IApiContext, orgId: string, menuId: string, placementSlug: string, iikoTableId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-iiko-table-binding`, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId,
                menuId: menuId,
                placementSlug: placementSlug,
                iikoTableId: iikoTableId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.create_iiko_table_binding_success(), this.i18n.create_iiko_table_binding_failed())
    }

    async deleteIikoTableBinding(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-iiko-table-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_iiko_table_binding_success(), this.i18n.delete_iiko_table_binding_failed())
    }

    // JETPAY

    async getJetpaySettings(ctx: IApiContext, orgId: string,): Promise<IApiResult<{
        projectId: number,
        encryptedSecretKey: string
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/jetpay-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_jetpay_settings_failed())
    }

    async saveJetpaySettings(ctx: IApiContext, settings: JetPay): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/save-jetpay-payment-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(settings)
        });
        return this.handleStatusError(ctx, res, this.i18n.save_jetpay_settings_success(), this.i18n.save_jetpay_settings_failed())
    }

    async encodeJetpayPaymentKey(ctx: IApiContext, secretKey: string): Promise<IApiResult<{ encryptedSecretKey: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/encode-jetpay-payment-key`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                secretKey: secretKey
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.encode_payment_key_failed())
    }

    // Customers

    async getCustomers(ctx: IApiContext, orgId: string, menuId: string, find: string, limit: number, skip: number,):
        Promise<IApiResult<{ customers: ICustomer[], count: number }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/customers`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId,
                menuId: menuId,
                find: find,
                limit: limit,
                skip: skip
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_customers_failed())
    }

    // KASPI

    async getKaspiPaymentSettings(ctx: IApiContext, organizationId: string,): Promise<IApiResult<IKaspiPaymentSettings>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/kaspi-payment-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                organizationId: organizationId,
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_kaspi_payment_settings_failed())
    }

    async getKaspiPaymentDeviceBindings(ctx: IApiContext, menuId: string,): Promise<IApiResult<IKaspiPaymentDeviceBinding>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/kaspi-payment-device-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_kaspi_payment_device_binding_failed())
    }

    async getKaspiPaymentTradePoints(ctx: IApiContext, organizationId: string,): Promise<IApiResult<IKaspiPaymentTradePoint>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/kaspi-payment-trade-points`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                organizationId: organizationId,
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_kaspi_payment_trade_points_failed())
    }

    async createKaspiPaymentDeviceBinding(ctx: IApiContext, menuId: string, tradePointId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-kaspi-payment-device-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                tradePointId: tradePointId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.create_kaspi_payment_device_binding_success(), this.i18n.create_kaspi_payment_device_binding_failed())
    }

    async createKaspiPaymentSettings(ctx: IApiContext, organizationId: string, apiKey: string): Promise<IApiResult<{
        id: string
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-kaspi-payment-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                organizationId: organizationId,
                apiKey: apiKey,
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.create_kaspi_payment_settings_success(), this.i18n.create_kaspi_payment_settings_failed())
    }

    async deleteKaspiPaymentDeviceBinding(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-kaspi-payment-device-binding`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_kaspi_payment_device_binding_success(), this.i18n.delete_kaspi_payment_device_binding_failed())
    }

    async deleteKaspiPaymentSettings(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-kaspi-payment-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_kaspi_payment_settings_success(), this.i18n.delete_kaspi_payment_settings_failed())
    }

    async SetManuallyPaymentStatusApproved(ctx: IApiContext, menuId: string, orderId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/set-manually-payment-status-approved`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                orderId: orderId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.set_manually_payment_status_approved_success(), this.i18n.set_manually_payment_status_approved_failed())
    }

    async SetManuallyPaymentStatusCancelled(ctx: IApiContext, menuId: string, orderId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/set-manually-payment-status-cancelled`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                orderId: orderId
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.set_manually_payment_status_cancelled_success(), this.i18n.set_manually_payment_status_cancelled_failed())
    }

    async SaveItemMedia(ctx: IApiContext, menuId: string, itemId: string, images: string[]): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/save-item-media`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                itemId: itemId,
                images: images
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.save_item_media_success(), this.i18n.save_item_media_failed())
    }

    async getMenuItemMedia(ctx: IApiContext, itemId: string,): Promise<IApiResult<IMenuItemMedia>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-item-media`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                itemId: itemId
            })
        });

        return this.handleStatusError(ctx, res, "", this.i18n.get_menu_item_media_failed())
    }

    async getOrganizationsForCopyMenu(ctx: IApiContext, find: string, limit: number, orgId: string,): Promise<IApiResult<IOrgForCopyMenu>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/organizations-for-copy-menu`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId,
                find: find,
                limit: limit
            })
        });

        return this.handleStatusError(ctx, res, "", this.i18n.get_organizations_for_copy_menu_failed())
    }

    async copyMenuToAnotherOrg(ctx: IApiContext, srcMenuId: string, dstOrgId: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/copy-menu-to-another-org`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                srcMenuId: srcMenuId,
                dstOrgId: dstOrgId
            })
        });

        return this.handleStatusError(ctx, res, this.i18n.copy_menu_to_another_org_success(), this.i18n.copy_menu_to_another_org_failed())
    }

    async getReportRarelyVisitedMenus(ctx: IApiContext, q: IReportsQuery): Promise<IApiResult<IReportsResponse>> {
        const res = this.fetchWithTimeout(ctx, `${NLTXURL}/report-rarely-visited-menus`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // 'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(q),
        });

        return this.handleStatusError(ctx, res, "", "")
    }

    async downloadOrderReports(ctx: IApiContext, menuId: string, from: number, to: number): Promise<IApiResult<{}>> {
        const res = await this.fetchWithTimeout(ctx, `${URL}/download-order-reports`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                from: from,
                to: to
            })
        });
        if (res.headers.get('content-type') === 'application/json') {
            return this.handleStatusError(ctx, Promise.reject(res), "", this.i18n.download_qr_code_failed())
        }
        let filename = decodeURIComponent((res.headers.get('Content-Disposition') || "").split("filename*=UTF-8''")[1].split(';')[0]);
        downloadBlob(filename, await res.blob())
        return {
            "requestId": "string",
            "success": true,
            "error": undefined,
            "data": {}
        }
    }

    async downloadCustomerReports(ctx: IApiContext, orgId: string, menuId: string): Promise<IApiResult<{}>> {
        const res = await this.fetchWithTimeout(ctx, `${URL}/download-customer-reports`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                orgId: orgId,
                menuId: menuId,
            })
        });
        if (res.headers.get('content-type') === 'application/json') {
            return this.handleStatusError(ctx, Promise.reject(res), "", this.i18n.download_qr_code_failed())
        }
        let filename = decodeURIComponent((res.headers.get('Content-Disposition') || "").split("filename*=UTF-8''")[1].split(';')[0]);
        downloadBlob(filename, await res.blob())
        return {
            "requestId": "string",
            "success": true,
            "error": undefined,
            "data": {}
        }
    }

    async getOrderSupItems(ctx: IApiContext, menuId: string): Promise<IApiResult<IOrderSupItem[]>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/order-sup-items`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_order_sup_items_failed())
    }

    async disableOrderSupItem(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/disable-order-sup-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.disable_order_sup_item_success(), this.i18n.disable_order_sup_item_failed())
    }

    async enableOrderSupItem(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/enable-order-sup-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.enable_order_sup_item_success(), this.i18n.enable_order_sup_item_failed())
    }

    async createOrderSupItem(ctx: IApiContext, menuId: string, name: I18nString, price: number, minQuantity: number): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/create-order-sup-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                name: name,
                price: price,
                minQuantity: minQuantity
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.create_order_sup_item_success(), this.i18n.create_order_sup_item_failed())
    }

    async updateOrderSupItem(ctx: IApiContext, menuId: string, id: string, name: I18nString, price: number, minQuantity: number): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-order-sup-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId,
                id: id,
                name: name,
                price: price,
                minQuantity: minQuantity
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.update_order_sup_item_success(), this.i18n.update_order_sup_item_failed())
    }

    async deleteOrderSupItem(ctx: IApiContext, id: string): Promise<IApiResult<{}>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-order-sup-item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        });
        return this.handleStatusError(ctx, res, this.i18n.delete_order_sup_item_success(), this.i18n.delete_order_sup_item_failed())
    }

    async getMenuInformationalPaymentMethods(ctx: IApiContext, menuId: string): Promise<IApiResult<{
        paymentMethods: string[]
    }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/menu-informational-payment-methods`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", this.i18n.get_menu_informational_payment_methods_failed())
    }

    async getIikoStopList(ctx: IApiContext, menuId: string): Promise<IApiResult<IStopList>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-stop-list`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                menuId: menuId
            })
        });
        return this.handleStatusError(ctx, res, "", "")
    }

    //==================================================================================================
    //===========================================  Domain  =============================================
    //==================================================================================================

    async getOrgDomainSettngs(ctx: IApiContext, orgId: string): Promise<IApiResult<DomainSettings>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/organization-domain-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({"orgId": orgId})
        });
        return this.handleStatusError(ctx, res, "", "")
    }

    async updateOrgDomainSettings(ctx: IApiContext, settings: DomainSettings): Promise<IApiResult<null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/update-org-domain-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({...settings})
        });
        return this.handleStatusError(ctx, res, "", "")
    }

    async deleteOrgDomainSettings(ctx: IApiContext, orgId: string): Promise<IApiResult<null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/delete-org-domain-settings`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({"orgId": orgId})
        });
        return this.handleStatusError(ctx, res, "", "")
    }

    //==================================================================================================
    //===================================  IIko Webhooks Settings  =====================================
    //==================================================================================================

    async getIikoWebhooksSettings(ctx: IApiContext, menuId: string, organizationId: string): Promise<IApiResult<WebhookSettings>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/q/iiko-webhook-config`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({"menuId": menuId, "organizationId": organizationId})
        });
        return this.handleStatusError(ctx, res, "", "")
    }

    async makeConfigWebhooksSettings(ctx: IApiContext, menuId: string): Promise<IApiResult<WebhookSettings>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/cmd/configure-iiko-webhook`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang,
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({"menuId": menuId})
        });
        return this.handleStatusError(ctx, res, "", "")
    }

    async getHistory(ctx: IApiContext, data: {startDate: string, endDate: string}): Promise<Blob> {
        const response = await this.fetchWithTimeout(ctx, `${URL}/download-user-history`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.blob();
    }

    async addOrgCrutches(ctx: IApiContext, data: AdditionalSettings): Promise<IApiResult<null>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/upsert-org-crutches`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(data)
        });
        return this.handleStatusError(ctx, res, this.i18n.save_iiko_settings_success(), "Error")
    }

    async getMenuVisitingStatistics(ctx: IApiContext, data: {fromDate: string, toDate: string, menuId: string}): Promise<IMenuStats> {
        const res = await this.fetchWithTimeout(ctx, `${URL}/download-customer-visited-report`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(data)
        });

        if (!res.ok) {
            throw new Error(`Failed to fetch data: ${res.status} ${res.statusText}`);
        }

        const result: IMenuStats = await res.json();

        if (!result.menuId || !result.fromDate || !result.toDate || !result.visitsData) {
            throw new Error("Invalid response format");
        }

        return result;
    }

    async getMonthlyColaReport(ctx: IApiContext, month: string): Promise<Blob> {
        const response = await this.fetchWithTimeout(ctx, `${URL}/download-cola-report`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({month: month})
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.blob();
    }

    async getMonthlyReport(ctx: IApiContext, data: {month: string, menuId: string}): Promise<IApiResult<{ reportText: string }>> {
        const res = this.fetchWithTimeout(ctx, `${URL}/get-menu-monthly-report`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Accept-User-Lang': this.lang
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(data)
        });
        
        return this.handleStatusError(ctx, res, "", "")
    }
}
